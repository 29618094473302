// MATRIX NILAI SECTION 1
export const eng_secone = [
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    32,
    33,
    35,
    37,
    38,
    37,
    41,
    41,
    42,
    43,
    44,
    45,
    45,
    46,
    47,
    47,
    48,
    48,
    49,
    49,
    50,
    51,
    51,
    52,
    52,
    53,
    54,
    54,
    55,
    56,
    57,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    65,
    66,
    67,
    68
]
// MATRIX NILAI SECTION 2
export const eng_sectwo = [
    20,
    20,
    21,
    22,
    23,
    25,
    26,
    27,
    29,
    31,
    33,
    35,
    36,
    37,
    38,
    40,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    60,
    61,
    63,
    65,
    67,
    68
]
// MATRIX NILAI SECTION 3
export const eng_secthree = [
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    28,
    29,
    30,
    31,
    32,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    43,
    44,
    45,
    46,
    46,
    47,
    48,
    48,
    49,
    50,
    51,
    52,
    52,
    53,
    54,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    63,
    65,
    66,
    67
]

export const ind_secone = [
    0,0,0,0,0,
    22,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    40,
    41,
    41,
    43,
    44,
    44,
    45,
    46,
    46,
    47,
    47,
    48,
    48,
    49,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    60,
    61,
    62,
    63,
    65,
    66,
    67,
    67,
    68,
    68,
    69,
    69,
    70
]

export const ind_sectwo = [
    0,0,0,0,0,
    22,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    40,
    42,
    44,
    46,
    46,
    48,
    48,
    50,
    50,
    52,
    52,
    54,
    54,
    56,
    56,
    58,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70
]

export const ind_secthree = [
    0,0,0,0,0,
    22,
    24,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    40,
    41,
    41,
    43,
    44,
    44,
    45,
    46,
    46,
    47,
    47,
    48,
    48,
    49,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    60,
    61,
    62,
    63,
    65,
    66,
    67,
    67,
    68,
    68,
    69,
    69
]
// HITUNG JAWABAN BENAR
export const count_right = (exam, questions, answer) => {
    let res = 0
    if(exam === 2) {
        questions.data.forEach((passage, i) => {
            passage.questions.forEach((question, j) => {
                if(answer != null) {
                    if(typeof answer[i][j] !== "undefined" && answer[i] != null && answer[i][j] === question.rightanswer) {
                        res+=1
                    }
                }
            })
        })
    }else{
        questions.data.forEach((v, i) => {
        
            if(answer != null){
                if(typeof answer[i] !== "undefined" && answer[i] != null && answer[i] === v.rightanswer) {
                    res += 1                  
                }
            }
        })
    }

    return res
}
// AMBIL DATA SCORE PER SECTION
export const get_section_score = (examtype, secIndex) => {
    let sc = JSON.parse(localStorage.getItem("score"))
    if (examtype === "TOEFL") {
        switch (secIndex) {
            case 0:
                return eng_secone[sc[secIndex]]
            case 1:
                return eng_sectwo[sc[secIndex]]
            case 2:
                return eng_secthree[sc[secIndex]]
            default:
                return 0
        }
    }

    if (examtype === "B.INDONESIA") {
        switch (secIndex) {
            case 0:
                return ind_secone[sc[secIndex]]
            case 1:
                return ind_sectwo[sc[secIndex]]
            case 2:
                return ind_secthree[sc[secIndex]]
            default:
                return 0
        }
    }

    if (examtype === "TOAFL") {
        switch (secIndex) {
            case 0:
                return sc[secIndex]
            case 1:
                return sc[secIndex]
            case 2:
                return sc[secIndex]
            default:
                return 0
        }
    }
}

export const get_real_section_score = (secIndex) => {
    let sc = JSON.parse(localStorage.getItem("score"))
    switch (secIndex) {
        case 0:
            return sc[secIndex]
        case 1:
            return sc[secIndex]
        case 2:
            return sc[secIndex]
        default:
            return 0
    }
}
// NILAI KESELURUHAN
export const eng_score = () => {
    let sc = JSON.parse(localStorage.getItem("score"))

    sc[0] = eng_secone[sc[0]]
    sc[1] = eng_sectwo[sc[1]]
    sc[2] = eng_secthree[sc[2]]
    let total = sc.reduce(function(c, i) { return c+i} )

    return (sc === null) ? 0 : (total / sc.length) * 10
}

export const ind_score = () => {
    let sc = JSON.parse(localStorage.getItem("score"))

    sc[0] = ind_secone[sc[0]]
    sc[1] = ind_sectwo[sc[1]]
    sc[2] = ind_secthree[sc[2]]
    let total = sc.reduce(function(c, i) { return c+i} )

    return (sc === null) ? 0 : (total / sc.length) * 10
}

export const hundredMark = (totalQ) => {
    let sc = JSON.parse(localStorage.getItem("score"))
    let totalR = 0
    sc.forEach(v => {
        totalR += v
    })
    let totalscore = (totalR/totalQ) * 100;
    return Math.round(totalscore)
}

export const calculate_section_result = (data,index) => {
    let right = count_right(index, data, JSON.parse(localStorage.getItem("answer")));

    let score = (typeof localStorage.getItem("score") !== "undefined" && localStorage.getItem("score") != null) ? JSON.parse(localStorage.getItem("score")) : [] ;
    
    score[index] = (right != null) ? right : 0;
    
    localStorage.setItem("score", JSON.stringify(score));
    localStorage.removeItem("answer");
}

export const arabic_score = () => {
    let sc = JSON.parse(localStorage.getItem("score"))

    let total = sc.reduce(function(c, i) { return c+i })

    return (sc === null) ? 0 : ((total+60) / sc.length) * 10
}