import ExamManagement from './pages/admin-panel/exam_management'
import QuestionBank from './pages/admin-panel/questions/question_bank'
import AccountManagement from './pages/admin-panel/account_management'
import MyProfile from './pages/admin-panel/myprofile'
import Setting from './pages/admin-panel/settings/settings'
import RegisterManagement from './pages/admin-panel/register_management'
import params from './helper/params'

const routes = [
    {
        path: '/console',
        component: ExamManagement,
        menu: 'EXAM',
        submenu: [
            {
                name: "Schedule",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.MANAGER,
                    params.user_role.INVIGILATOR
                ]
            },
            {
                name: "Participant Database",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.MANAGER
                ]
            },
            {
                name: "Certificate",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.MANAGER
                ]
            }
        ]
    },
    {
        path: '/console/registration',
        component: RegisterManagement,
        menu: 'REGISTRATION'
    },
    {
        path: '/console/questionbank',
        component: QuestionBank,
        menu: 'QUESTION',
        submenu: [
            {
                name: "Indicator",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.QUESTIONER
                ]
            },
            {
                name: "Instruction",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.QUESTIONER
                ]
            },
            {
                name: "Packet",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.QUESTIONER
                ]
            },
            {
                name: "Audio File",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.QUESTIONER
                ]
            }
        ]
    },
    {
        path: '/console/accounts',
        component: AccountManagement,
        menu: 'ACCOUNT'
    },
    {
        path: '/console/myprofile',
        component: MyProfile,
        menu: 'MYPROFILE'
    },    
    {
        path: '/console/settings',
        component: Setting,
        menu: 'SETTING',
        submenu: [
            {
                name: "Exam",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.MANAGER
                ]
            },
            {
                name: "Venue",
                access: [
                    params.user_role.ADMIN,
                    params.user_role.MANAGER
                ]
            },
            {
                name: "Email Editor",
                access: [
                    params.user_role.ADMIN
                ]
            }
        ]
    }
]

export default routes