import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import params from 'helper/params';
import lang from 'helper/localize';
import {NavLink} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { set_submenu_index } from 'store/actions/subMenuAction';

const categories = [
  {
    id: 'Main Menu',
    children: [
      { id: lang.exam, icon: <AssignmentIcon/>, menu: params.menu.EXAM, access: [
        params.user_role.ADMIN,
        params.user_role.MANAGER,
        params.user_role.INVIGILATOR
      ], route: "/console" },
      { id: lang.register, icon: <GroupAddIcon />, menu: params.menu.REGISTRATION, access: [
        params.user_role.ADMIN,
        params.user_role.MANAGER
      ], route: "/console/registration" },
      { id: lang.question_bank, icon: <LiveHelpIcon />, menu: params.menu.QUESTION, access: [
        params.user_role.ADMIN,
        params.user_role.QUESTIONER
      ], route: "/console/questionbank" },
      { id: lang.account, icon: <AccountCircle />, menu: params.menu.ACCOUNT, access: [
        params.user_role.ADMIN
      ], route: "/console/accounts" },
      { id: lang.setting, icon: <SettingsIcon/>, menu: params.menu.SETTING, access: [
        params.user_role.ADMIN,
        params.user_role.MANAGER
      ], route: "/console/settings" }
    ],
  }
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;
  const selectedMenu = props.menu;
  const user = JSON.parse(localStorage.getItem('auth'))

  const dispatch = useDispatch()

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          CBT UIN JKT
        </ListItem>
        {/* <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Project Overview
          </ListItemText>
        </ListItem> */}
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(function ({ id: childId, icon, route, menu, access }, i) {
              let nav = null
              if (access && access.includes(user.role)) {
                nav = <NavLink to={route} key={i}>
                        <ListItem
                          key={childId}
                          button
                          onClick={() => dispatch(set_submenu_index(0))}
                          className={clsx(classes.item, menu === selectedMenu && classes.itemActiveItem)}
                        >
                          <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                          <ListItemText
                            classes={{
                              primary: classes.itemPrimary,
                            }}
                          >
                            {childId}
                          </ListItemText>
                        </ListItem>
                      </NavLink>
              }
              return nav
            })}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);