import React, {useState, useEffect} from 'react'
import { AppBar, Toolbar, Button, makeStyles, Container, Grid, Paper, FormControl, TextField, InputAdornment, Fade, RadioGroup, Radio, FormLabel, FormControlLabel, InputLabel, Select, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';

import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import { registerExam, uploadPaymentReceipt, getParticipantById } from 'model/exammodel'
import moment from 'moment'
import {show_alert} from 'store/actions/alertActions'
import {showloader, hideloader} from 'store/actions/loaderActions'
import {useDispatch} from 'react-redux'

import 'main.scss'
import { show_dialog, hide_dialog } from 'store/actions/dialogActions'
import { faculty, majors } from 'helper/uinmajors'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import lang from 'helper/localize'
import Dropzone from 'react-dropzone'
import { Alert } from '@material-ui/lab';
import queryString from 'query-string'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow:1,
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },

    identifyBox: {
        padding: 16
    },

    buttontoken: {
        height: 55,
    },

    smalltext: {
        fontSize: 12,
        padding: 0
    },

    calendarcontainer: {
        padding:16
    },

    formregister: {
        padding: 16
    },

    textInput: {
        marginBottom:10
    },

    dropzone: {
        marginTop:10,
        justifyContent:'center',
        alignContent: 'center',
        height:200,
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        padding:32
    },
  }));

const Registertest = props => {

    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const {register, handleSubmit, control, errors } = useForm()

    const [disableForm, setDisableForm] = useState(false)
    const [selectedExam, setSelectedExam] = useState({
        data: {},
        extend: {},
        quota:0
    })
    const [genderValue, setGenderValue] = useState("female")
    const [statusValue, setStatusValue] = useState("student")
    const [programValue, setProgramValue] = useState("s1")
    const [formState, setFormState] = useState("payment")
    const [selectedFaculty, setSelectedFaculty] = useState(0)
    const [uinFaculty, setUinFaculty] = useState("")
    const [uinMajors, setUinMajors] = useState("")
    const [birthdate, setbirthdate] = useState(null)
    const [qs, setQs] = useState({})
    var [userId, setUserId] = useState("")
    var [userData, setUserData] = useState({
        'name': "",
        'nid': "",
        'type': ''
    })

    const handleRegister = (data, e) => {
        data.exam = selectedExam.data.id
        data.faculty = uinFaculty
        data.majors = uinMajors
        data.program = programValue
        data.gender = genderValue
        data.occupation = statusValue
        data.birthdate = birthdate
        data.registrationfee = (statusValue === "general") ? 150000 : 75000
        dispatch(showloader("Processing registration..."))
        registerExam(data).then(res => {
            setFormState("payment")
            setUserData({
                'name': res.data.name,
                'nid': res.data.nid,
                'type': res.data.occupation
            })
            setUserId(res.data._id)
            dispatch(show_dialog({
                title: "Registration Success",
                message: "Your registration success, please proceed your payment and upload payment receipt."
            }, () => {
                dispatch(hide_dialog())
            }))

            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            e.target.reset()
            setDisableForm(true)
            setSelectedExam({
                data: {},
                extend: {},
                quota: 0
            })
            dispatch(hideloader())
        })
    }
    
    const onFacultyChange = (e) => {
        setSelectedFaculty(faculty.indexOf(e.target.value))
        setUinFaculty(e.target.value)
        setUinMajors(majors[faculty.indexOf(e.target.value)][0])
    }

    const onMajorsChange = (e) => {
        setUinMajors(e.target.value)
    }

    const handleBirthdateChange = e => {
        let dt = moment(e).format("YYYY-MM-DD")
        setbirthdate(dt);
    }

    const handlePaymentReceipt = file => {
        dispatch(showloader("Processing Payment receipt..."))
        let formData = new FormData()
        formData.append('file', file[0])
        uploadPaymentReceipt(userId, formData).then(res => {
            setFormState("complete")
            dispatch(show_alert({
            status: 'success',
            message: res.message
            }))
        }).catch(err => { 
            dispatch(show_alert({
            status: 'error',
            message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    useEffect(() => {
        setQs(queryString.parse(props.location.search))

        if (qs.user) {
            dispatch(showloader("Loading data..."))
            getParticipantById(qs.user).then(res => {
                console.log(res);
                if (res.data !== null) {
                    if (res.data.payment_receipt && (res.data.payment_receipt !== "" || res.data.payment_receipt !== null)) {
                        setFormState("complete")
                    } else {
                        setUserId(qs.user)
                        setUserData({
                            'name': res.data.name,
                            'nid': res.data.nid,
                            'type': res.data.occupation
                        })

                        setFormState("payment")
                    }
                } else {
                    setFormState("register")
                }
            }).catch(err => {
                console.error(err);
                setFormState("register")
            }).finally(() => {
                dispatch(hideloader())
            })
        } else {
            setFormState("register")
        }
    }, [qs.user, props.location.search, dispatch])

    return <Fade in={true} timeout={500}>
        <div className="App-header2">
            <AppBar position="fixed" color="inherit" elevation={0}>
                <Toolbar>
                    <Button style={{color: 'black'}} className={classes.backMenu} startIcon={<ArrowBackIcon/>} onClick={() => history.replace('/')}> HOME</Button>
                    <div className={classes.root}></div>
                    <Button style={{color: 'black'}} startIcon={<EditIcon/>} onClick={() => history.replace('/register')}> REGISTER </Button>
                    <Button style={{color: 'black'}} startIcon={<EventIcon/>} onClick={() => history.replace('/reschedule')}> RESCHEDULE </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg" style={{marginTop:80,marginBottom:80}}>
                <Grid container spacing={1} style={{flexGrow: 1}} justify="center">
                    <Grid item lg={8} sm={12} xs={12}>
                        {
                            (formState === "register") ? 
                            <Paper>
                                <form onSubmit={handleSubmit(handleRegister)} key="registerform">
                                    <div className={classes.formregister}>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'name'},{required: 'Name is required'})}
                                                    helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                                                    disabled={disableForm}
                                                    label="Name"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your Name"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircleIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="name" control={control} defaultValue="" />      
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.nid) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="nid"/>}
                                                    disabled={disableForm}
                                                    label="NIM"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your NIM/NIK/NIP"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <RecentActorsIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="nid" control={control} defaultValue="" rules={{required: 'NIM is required'}} />
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Gender</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender" row value={genderValue} onChange={(e) => setGenderValue(e.target.value)}>
                                                <FormControlLabel
                                                value="female"
                                                control={<Radio color="primary" />}
                                                label="Female"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                value="male"
                                                control={<Radio color="primary" />}
                                                label="Male"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.birthplace) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthplace"/>}
                                                    disabled={disableForm}
                                                    label="Birthplace"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your birthplace"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EventIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="birthplace" control={control} defaultValue="" rules={{required: 'This field is required'}} />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                value={birthdate}
                                                onChange={handleBirthdateChange}
                                                format="yyyy-MM-dd"
                                                id="date-picker-inline"
                                                label="Birthdate"
                                                disabled={disableForm}
                                                placeholder="yyyy-mm-dd"
                                                ref={register}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                helperText={(errors.birthdate) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthdate"/>}
                                            />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'email'},{required: 'Email is required'})}
                                                    helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                                    disabled={disableForm}
                                                    label="E-mail"
                                                    type="email"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your E-mail"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EmailIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="email" control={control} defaultValue="" />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'phone'},{required: 'Phone is required'})}
                                                    helperText={(errors.phone) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="phone"/>}
                                                    disabled={disableForm}
                                                    label="Phone"
                                                    type="number"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your phone number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PhoneIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="phone" control={control} defaultValue="" />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.address) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="address"/>}
                                                    disabled={disableForm}
                                                    label="Address"
                                                    type="number"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your address"
                                                    multiline
                                                    rows="4"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EditIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="address" control={control} defaultValue="" rules={{required: 'Address is required'}} />
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Status</FormLabel>
                                            <RadioGroup aria-label="status" name="status" row value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                                <FormControlLabel
                                                value="student"
                                                control={<Radio color="primary" />}
                                                label="UIN Jakarta Student"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                value="employee"
                                                control={<Radio color="primary" />}
                                                label="UIN Jakarta Teacher/Employee"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                value="general"
                                                control={<Radio color="primary" />}
                                                label="General"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {
                                            (statusValue === "student") && <div>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Program</FormLabel>
                                                    <RadioGroup aria-label="program" name="program" row value={programValue} onChange={(e) => setProgramValue(e.target.value)}>
                                                        <FormControlLabel
                                                        value="s1"
                                                        control={<Radio color="primary" />}
                                                        label="S1"
                                                        disabled={disableForm}
                                                        labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                        value="s2"
                                                        control={<Radio color="primary" />}
                                                        label="S2"
                                                        disabled={disableForm}
                                                        labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                        value="s3"
                                                        control={<Radio color="primary" />}
                                                        label="S3"
                                                        disabled={disableForm}
                                                        labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="selectfacultylabel">Faculty</InputLabel>
                                                    <Select
                                                        defaultValue=""
                                                        value={uinFaculty}
                                                        disabled={disableForm}
                                                        labelId="selectfacultylabel"
                                                        id="selectfaculty"
                                                        onChange={(e) => onFacultyChange(e)}
                                                        >
                                                            {faculty.map((v, i) => (
                                                                <MenuItem key={i} value={v}>{v}</MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="selectmajorlabel">Majors</InputLabel>
                                                    <Select
                                                        defaultValue=""
                                                        value={uinMajors}
                                                        disabled={disableForm}
                                                        labelId="selectmajorlabel"
                                                        id="selectmajor"
                                                        onChange={(e) => onMajorsChange(e)}
                                                        >
                                                            {majors[selectedFaculty].map((v, i) => (
                                                                <MenuItem key={i} value={v}>{v}</MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        }
                                        <Button variant="contained" fullWidth={true} color="primary" style={{marginTop:28}} disabled={disableForm} type="submit">REGISTER</Button>
                                    </div>
                                </form>
                            </Paper>
                            :
                            (formState === "payment") ? 
                            <Paper style={{padding:16}}>
                                <h1>Upload payment receipt</h1>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>: {userData.name}</td>
                                        </tr>
                                        <tr>
                                            <td>NID</td>
                                            <td>: {userData.nid}</td>
                                        </tr>
                                        <tr>
                                            <td>TYPE</td>
                                            <td>: {userData.type}</td>
                                        </tr>
                                        <tr>
                                            <td>Registration Fee</td>
                                            <td>: Rp{(userData.type === "general") ? "150.000" : "75.000"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br/>
                                <Alert severity="info">Upload your payment receipt in form below</Alert>
                                <Dropzone multiple={false} disabled={false} onDrop={acceptedFiles => handlePaymentReceipt(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()} className={classes.dropzone}>
                                        <input {...getInputProps()} />
                                        <p>{lang.drag_drop_click}</p>
                                        </div>
                                    </section>
                                    )}
                                </Dropzone>
                            </Paper>
                            :
                            <Paper>
                                <Alert severity="info">
                                The results of the potential participant's leverage will be received a maximum of 2x24 hours (only on working days / hours). Check your email regularly for further information.
                                </Alert>
                            </Paper>
                        }
                    </Grid>
                </Grid>
            </Container>
        </div>
    </Fade>
}

export default Registertest