import React, {useState, useEffect} from 'react'
import {Grid, FormControl, InputLabel, Select, MenuItem, makeStyles, TextField, InputAdornment, Button} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import EmailIcon from '@material-ui/icons/Email';
import Content from 'template/Content'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import lang from 'helper/localize';
import {useDispatch} from 'react-redux'
import userdata from 'helper/userdata';
import { getEmailTemplate } from 'model/emailmodel';
import { showloader } from 'store/actions/loaderActions';
import { show_alert } from 'store/actions/alertActions';
import { hideloader } from 'store/actions/loaderActions';
import { testSendTemplateEmail } from 'model/emailmodel';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'assets/css/react-draft-wysiwyg.css';
import { updateSendTemplateEmail } from 'model/emailmodel';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const EmailSetting = (props) => {

    const classes = useStyles()
    const { user, token } = userdata
    const [selectedEmailType, setSelectedEmailType] = useState("")
    const {register, handleSubmit, control, errors } = useForm({
        defaultValues: {
            email: user.email
        }
    })
    const [listEmailTemplate, setListEmailTemplate] = useState([])
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({})
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setSelectedEmailType(e.target.value)
        let emailIndex = listEmailTemplate.map(d => d._id).indexOf(e.target.value)
        setSelectedEmailTemplate(listEmailTemplate[emailIndex])

        const contentBlock = htmlToDraft(listEmailTemplate[emailIndex].template);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
    }

    const handleTestSendEmail = (data, e) => {
        dispatch(showloader(lang.send_email_process))
        testSendTemplateEmail(token, {email_type: selectedEmailTemplate.email_type, email: data.email})
            .then(data => {
                dispatch(show_alert({
                    status: 'success',
                    message: data.message
                }))
            })
            .catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => dispatch(hideloader()))
    }

    const refreshData = (index = 0) => {
        dispatch(showloader(lang.loading))
        getEmailTemplate(token).then(data => {
            let { data: templateList } = data
            setListEmailTemplate(templateList)
            setSelectedEmailType(templateList[index]._id)
            setSelectedEmailTemplate(templateList[index])
            const contentBlock = htmlToDraft(templateList[index].template);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => dispatch(hideloader()))
    }

    const handleEditorStateChange = (state) => {
        setEditorState(state)
    }

    const handleSaveEmailTemplate = () => {
        let template = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let updatedTemplate = selectedEmailTemplate
        updatedTemplate.template = template
        dispatch(showloader(lang.loading))
        updateSendTemplateEmail(token, updatedTemplate._id, updatedTemplate)
            .then(data => {
                dispatch(show_alert({
                    status: 'success',
                    message: data.message
                }))
                let emailIndex = listEmailTemplate.map(d => d._id).indexOf(updatedTemplate._id)
                refreshData(emailIndex)
            })
            .catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => dispatch(hideloader()))
    }

    useEffect(refreshData, [])

    return <Content>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" direction="row">
            <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-email-type-label">Email type</InputLabel>
                    <Select
                    labelId="select-email-type-label"
                    id="select-email-type"
                    value={selectedEmailType}
                    onChange={handleChange}
                    label="Email Type"
                    >
                        {listEmailTemplate.map((v, i) => <MenuItem value={v._id} key={i}>
                            <em>{v.email_type}</em>
                        </MenuItem>)}
                        
                    </Select>
                </FormControl>
            </Grid>

            <Grid item>
                <form onSubmit={handleSubmit(handleTestSendEmail)}>
                    <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item>
                        <FormControl>
                            <Controller as={
                                <TextField
                                    ref={register({name: 'email'}, {required: 'Email is required'})}
                                    helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                    label="E-mail"
                                    type="email"
                                    id="email"
                                    placeholder="Input e-mail for testing"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon style={{color: "#e0e0e0"}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            } name="email" control={control} />
                        </FormControl>
                        </Grid>
                        <Grid item>
                        <Button variant="contained" color="primary" type="submit">{lang.send_email}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
        <div>
            {(selectedEmailTemplate.description !== undefined) ? <Alert severity="info" style={{marginBottom:10}}>
                <AlertTitle>Info</AlertTitle>
                <strong>{ selectedEmailTemplate.description }</strong>
            </Alert> : <div><br/><br/></div>}
            <div>
            <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleEditorStateChange}/>
            </div>
            <Button variant="contained" color="primary" onClick={handleSaveEmailTemplate} style={{marginTop:10}}>
                {lang.save}
            </Button>
        </div>
        
    </Content>
}

export default EmailSetting