import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
    Container, 
    Grid, 
    FormControl, 
    InputLabel, 
    OutlinedInput, 
    Button, 
    Table, 
    TableBody, 
    TableRow, 
    TableCell,
    TableContainer,
    Grow,
    AppBar,
    Toolbar,
    Fade,
    Typography,
    Drawer,
    List,
    ListItemIcon,
    ListItem,
    Divider,
    ListItemText,
    IconButton,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import LockIcon from '@material-ui/icons/Lock';
import moment from 'moment-timezone'
import Countdown, {zeroPad} from 'react-countdown';
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import {showloader, hideloader} from 'store/actions/loaderActions'
import { show_alert} from 'store/actions/alertActions'
import {checkToken, saveScoreFromHistory} from 'model/participantmodel'
import { examStepArabic, testAlias } from 'helper/utils'
import lang from 'helper/localize'
import screenfull from 'screenfull'
import {set_exam_type} from 'store/actions/examActions'
import { show_dialog, hide_dialog } from 'store/actions/dialogActions'
import isElectron from 'is-electron'
import { reset_test } from 'store/actions/examActions'
import { updateExamLimit } from 'model/exammodel'
import {config} from 'helper/config'
import params from 'helper/params'
import { loadScoreFromHistory, saveScoreToHistory } from 'helper/examhistory'

import ComputerIcon from '@material-ui/icons/Computer';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },

    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },

    title: {
        flexGrow: 1,
        spacing: theme.spacing(1),
        color: 'black'
    },

    identifyBox: {
        padding: 16
    },

    buttontoken: {
        height: 55,
    },

    smalltext: {
        fontSize: 12,
        padding: 0
    },
  }));

const ResultBox = ({history}) => {

    const dispatch = useDispatch()
    const [showSaveButton, setShowSaveButton] = useState(history.saved)

    useEffect(() => {
        setShowSaveButton(history.saved)
    }, [history])

    const saveExamScore = async () => {
        var lastHistory = loadScoreFromHistory()
        var historyIndex = lastHistory.findIndex(x => x.token === history.token)

        dispatch(showloader(lang.auth))
        try {
            let data = await saveScoreFromHistory(history)

            lastHistory[historyIndex].saved = true

            saveScoreToHistory(lastHistory[historyIndex], historyIndex)
            setShowSaveButton(true)

            dispatch(show_alert({
                status: "success",
                message: data.message
            }))
        } catch (error) {
            lastHistory[historyIndex].saved = false
            setShowSaveButton(false)

            saveScoreToHistory(lastHistory[historyIndex], historyIndex)

            dispatch(show_alert({
                status: "error",
                message: error.message
            }))
        }

        dispatch(hideloader())
    }

    return  <Paper style={{padding:16}}><Container style={{padding:0}}>
        <Grid item lg={12} xs={12} sm={12} align="center">
            <p style={{fontSize:14}}>{history.name} <br/>{history.nik}</p>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} align="center">
            <TableContainer>
                <Table aria-label="simple table" size="small" dir={(history.examtype === params.examType.TOAFL) ? "rtl" : "ltr"}>
                    <TableBody>
                        {["LISTENING", "STRUCTURE", "READING"].map((row, i) => (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row" align={(history.examtype === params.examType.TOAFL) ? "right" : "left"}>
                                    <span style={{fontSize: 14, fontWeight: 'bold'}}>{(history.examtype === params.examType.TOAFL) ? examStepArabic(row) : row}</span>
                                </TableCell>
                                <TableCell align={(history.examtype === params.examType.TOAFL) ? "left" : "right"}>
                                    {(row === params.examStep.LISTENING) && history.listening}
                                    {(row === params.examStep.STRUCTURE) && history.structure}
                                    {(row === params.examStep.READING) && history.reading}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <Grid item xs={12} sm={12} align="center" style={{marginTop:20}}>
            {(showSaveButton === false) ? <Button variant="contained" color="primary" startIcon={<SaveIcon />} style={{marginLeft:10}} onClick={saveExamScore}>{lang.save_exam_score}</Button> : null}
        </Grid>
    </Container></Paper>
}

export default function Identification() {
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch()
    
    const [token, setToken] = useState("")
    const [identity, setIdentity] = useState(false)
    const [participantData, setParticipantData] = useState({})
    const examType = useSelector(state => state.examTypeState)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [listExamHistory, setListExamHistory] = useState([])
    const [examHistory, setExamHistory] = useState({})
    const [showHistory, setShowHistory] = useState(false)

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }
    
    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    function shuffle(array) {
        array.sort(() => Math.random() - 0.5);
    }

    function handleInputToken(e) {
        localStorage.removeItem("score")
        localStorage.removeItem("examdata")
        localStorage.removeItem("listening_answer")
        localStorage.removeItem("answer")
        localStorage.removeItem("auth")
        dispatch(reset_test())
        
        if (!token) {
            dispatch(show_alert({
                status: "error",
                message: "Please, input token!"
            }))
            return
        }

        dispatch(showloader(lang.auth))
        checkToken(token).then(res => {
            dispatch(set_exam_type(res.type))
            if(res.type === params.examType.TOAFL) {
                lang.setLanguage('id')
            }else{
                lang.setLanguage('en')
            }
            res.exam.forEach(async (v, i) => {
                v.data.forEach((vv, ii) => {
                    if(i < 2) {
                        if (res.exam[0].data[ii]) {
                            shuffle(res.exam[0].data[ii].options)
                        }
                    }else{
                        vv.questions.forEach((q, iii) => {
                            shuffle(q.options);
                        })
                    }
                })
            })
            setParticipantData(res)
            setIdentity(true)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleStartExam(e) {

        let instruction_alert = lang.formatString(lang.instruction_alert, (examType === params.examType.TOAFL) ? 12 : 6)

        if (examType === params.examType.TIPA) {
            instruction_alert = "Setelah percakapan selesai dibacakan, Anda hanya memiliki waktu kurang dari 15 detik untuk menjawab masing – masing pertanyaan sebelum dialog yang dibacakan."
        }

        dispatch(show_dialog({
            title: lang.attention,
            message: instruction_alert
        }, () => {
            dispatch(hide_dialog())
            if (screenfull.isEnabled) {
                screenfull.request();
            }
    
            participantData.exam[0].endtime = moment().add(40, 'minutes').format("YYYY-MM-DD HH:mm:ss")
            participantData.exam[1].endtime = moment().add(60, 'minutes').format("YYYY-MM-DD HH:mm:ss")
            participantData.exam[2].endtime = moment().add(115, 'minutes').format("YYYY-MM-DD HH:mm:ss")

            setParticipantData(participantData)
    
            localStorage.setItem('examdata', JSON.stringify(participantData))
    
            localStorage.setItem("auth", true)
            
            updateExamLimit(token).then(data => {
                history.push('/exam')
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            })
        }))
    }

    function handleTakeExam() {
        setOpenDrawer(false)
        setShowHistory(false)
    }

    function handleShowHistory(historyIndex) {
        setShowHistory(true)
        let history = loadScoreFromHistory()
        setExamHistory(history[historyIndex])
    }

    const handleQuitApp = () => {
        dispatch(show_dialog({
            title: lang.attention,
            message: lang.quit_app
        }, () => {
            window.open('', '_self', ''); window.close();
        }))
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Grow in={true}>
                {(isNaN(participantData.participant.totalresult)) ? <Button variant="contained" color="primary" fullWidth onClick={handleStartExam}>
                        {lang.start_test}
                    </Button> : <span style={{textAlign: "center"}}>Score: {participantData.participant.totalresult} </span>}
            </Grow>
        } else {
            // Render a countdown
            return <span>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    };

    useEffect(() => {
        if(!isElectron() && config.env === params.env.PROD) {
            history.replace('/')
        } else {
            let lastScore = loadScoreFromHistory()
            setListExamHistory(lastScore)
        }
    }, [history])


    return <Fade in={true} timeout={500}><div className="App-header">
        <AppBar position="fixed" elevation={4} color="inherit" className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
        })}>
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, openDrawer && classes.hide)}
            >
                <MenuIcon style={{color:"black"}} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            Pusat Pengembangan Bahasa UIN Syarif Hidayatullah Jakarta
            </Typography>
            <Button style={{color:"black"}} onClick={handleQuitApp}>Exit</Button>
        </Toolbar>
        </AppBar>
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{
            paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <Typography variant="h6" className={classes.title}>
                    &nbsp;&nbsp;&nbsp;Test History
                </Typography>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color:"black"}} /> : <ChevronRightIcon style={{color:"black"}}/>}
                </IconButton>
            </div>
            <Divider />
            <List>
                {(listExamHistory && listExamHistory.length > 0) ? listExamHistory.map((data, index) => (
                    <ListItem button key={data.token} onClick={() => handleShowHistory(index)}>
                        <ListItemIcon><AssignmentIcon /></ListItemIcon>
                        <ListItemText primary={data.examtype} secondary={`Score: ${data.totalscore}`} />
                        <ListItemText primary="&nbsp;" secondary={moment(data.starttime).fromNow()} />
                    </ListItem>
                )) 
                : <ListItem key="NO HISTORY">
                    <ListItemIcon><FolderOpenIcon /></ListItemIcon>
                    <ListItemText primary="NO HISTORY" />
                </ListItem>}
            </List>
            <Divider />
            <List>
                <ListItem button key="TAKE EXAM" onClick={handleTakeExam}>
                    <ListItemIcon> <ComputerIcon /> </ListItemIcon>
                    <ListItemText primary="TAKE EXAM" />
                </ListItem>
            </List>
        </Drawer>
        <Container maxWidth="lg" className={clsx(classes.content, {
            [classes.contentShift]: openDrawer,
            })}>
            {!identity ?
                <Container maxWidth="sm" style={{marginTop:280}}>
                    {(showHistory) ? 
                        <ResultBox history={examHistory} /> 
                    :
                        <div>
                            <Alert severity="info">
                                <AlertTitle>{lang.attention}</AlertTitle>
                                {lang.token_warning}
                            </Alert>
                            <br/>
                            <Paper elevation={3} className={classes.identifyBox}>
                                <Grid container spacing={1}>
                                    <Grid item xs={10} sm={10}>
                                        <FormControl fullWidth className={classes.root.margin} variant="outlined" required={true}>
                                            <InputLabel htmlFor="outlined-token">Token</InputLabel>
                                            <OutlinedInput
                                                onChange={e => setToken(e.target.value)}
                                                autoFocus={true}
                                                id="outlined-token"
                                                labelWidth={60}
                                                placeholder={lang.token_input}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} sm={2}>
                                        <Button variant="contained" color="primary" className={classes.buttontoken} onClick={handleInputToken} fullWidth>
                                            <LockIcon/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    }
                </Container>
            : 
                <Grow in={identity}>
                    <Container maxWidth="xs" style={{marginTop:280}}>
                        <Paper elevation={3} className={classes.identifyBox}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <p align="right" className={classes.smalltext} >{moment(participantData.exam_starttime).format("MMMM Do YYYY")}</p>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <h3 style={{padding:0,margin:0}}>{testAlias(participantData.type)}</h3>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                <fieldset style={{marginTop: 10, minWidth: 0}}>
                                    <legend style={{fontSize: 12}}>
                                        Participant
                                    </legend>
                                    <TableContainer>
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {lang.name}
                                                </TableCell>
                                                <TableCell align="left">: {participantData.participant.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {lang.no_id}
                                                </TableCell>
                                                <TableCell align="left">: {participantData.participant.no_id}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {lang.email}
                                                </TableCell>
                                                <TableCell align="left">: {participantData.participant.email}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {lang.phone}
                                                </TableCell>
                                                <TableCell align="left">: {participantData.participant.phone}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                    
                                </fieldset>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <p align="center" style={{marginBottom:0,marginTop:10 }}>
                                        <Countdown date={participantData.exam_starttime}
                                            zeroPadTime={2}
                                            zeroPadDays={2}
                                            renderer={renderer}
                                        >
                                        </Countdown>
                                    </p>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </Grow>
            } 
        </Container>
    </div>
    </Fade>
}