import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { jump_questions } from 'store/actions/examActions'
import clsx from 'clsx'
import params from 'helper/params'

const useStyle = makeStyles(theme => ({
    expression: {
        display: "inline-block",
        textAlign: "center",
        verticalAlign: "top",
        paddingLeft: '5px',
        paddingRight: '5px',
        '&:hover':{
            backgroundColor: '#e0e0e0',
            color: '#fff',
            cursor: 'pointer'
        }
    },
    arabicFont: {
        fontSize: 28,
        fontWeight: "bold",
        fontFamily: "Scheherazade"
    },
    normalFont: {
        fontSize: 16,
        fontWeight: "bold"
    },
}))

const ExpressionsQuestion = props => {
    const classes = useStyle()
    const dispatch = useDispatch()
    const [isChoice, setChoice] = useState(false)
    const examType = useSelector(state => state.examTypeState)

    useEffect(() => {
        setChoice(props.checked)
    }, [props.checked])

    const saveAnswer = (myanswer, no, answer) => {      
        var res = ""

        if (myanswer != null) {
            let mya = JSON.parse(myanswer)
            mya[no] = answer
            
            res = JSON.stringify(mya)
        } else {
            let mya = []
            mya[no] = answer
            res = JSON.stringify(mya)
        }
        
        return res
    }

    const handleClick = e => {
        localStorage.setItem("answer", saveAnswer(localStorage.getItem("answer"), props.no, props.answer))
        dispatch(jump_questions(0))
        dispatch(jump_questions(props.no))
    }

    return <div className={clsx(classes.expression, 
            (examType === params.examType.TOAFL) ? 
                classes.arabicFont 
                : classes.normalFont)} 
            style={{backgroundColor: (isChoice) ? 
                '#2196f3' 
                : 'transparent', 
            color: (isChoice) ? 
                '#fff' : 
                '#000'}} 
            onClick={handleClick}>

        <strong>
            <u>{props.answer}</u>
        </strong><br/>
        <strong>
            <span>{props.option}</span>
        </strong>
    </div>
}

export default ExpressionsQuestion