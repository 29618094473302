import React, {useState, useEffect} from 'react'
import { FormControl, InputLabel, Select, MenuItem, makeStyles, Grid, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dropzone from 'react-dropzone'
import {
    getListeningAudioList,
    deleteListeningAudio,
    uploadListeningAudio
} from 'model/questionmodel'
import {useDispatch} from 'react-redux'
import { show_alert } from 'store/actions/alertActions';
import { showloader, hideloader } from 'store/actions/loaderActions';
import { show_dialog, hide_dialog } from 'store/actions/dialogActions'

import lang from 'helper/localize'
import {testAlias} from 'helper/utils'

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
    formControl: {
      marginBottom:10
    },
    container: {
        padding: 15
    },
    dropzone: {
      marginTop:10,
      justifyContent:'center',
      alignContent: 'center',
      height:320,
      border: '1px solid #e0e0e0',
      borderRadius: 8,
      padding:32
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

const AudioUploader = props => {
    const classes = useStyles();
    const [testType, setTestType] = useState("TOEFL")
    const [audioType, setAudioType] = useState("questions")
    const [audioList, setAudioList] = useState([])
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const onChangeTestType = e => {
        setAudioList([])
        setTestType(e.target.value)
        getAudioList(e.target.value, audioType)
    }

    const onChangeAudioType = e => {
        console.log(e.target.value);
        
        setAudioList([])
        setAudioType(e.target.value)
        getAudioList(testType, e.target.value)
    }

    const getAudioList = (testType, audioType) => {
        let token = localStorage.getItem('token')
        dispatch(showloader("Listing data..."))
        getListeningAudioList(token, testType, audioType).then(res => {
            setAudioList(res);
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const handleDeleteAudio = (audio, testType, audioType) => {
        dispatch(show_dialog({
            title: lang.attention,
            message: lang.deleteconfirm
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader(lang.processing_delete))
            let token = localStorage.getItem('token')
            deleteListeningAudio(token, testType, audioType, audio).then(res => {
                dispatch(show_alert({
                    status: "success",
                    message: res
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: JSON.stringify(err.message)
                }))
            }).finally(() => {
                // dispatch(hideloader())
                setAudioList([])
                getAudioList(testType, audioType)
            })
        }))
    }

    const handleUploadAudio = (file, testType, audioType) => {
        console.log(file);
        dispatch(showloader(lang.process))
        file.forEach(fl => {
            let token = localStorage.getItem('token')
            let formData = new FormData()
            formData.append('file', fl)
            formData.append('testtype', testType)
            formData.append('audiotype', audioType)
            console.log(formData);
            
            uploadListeningAudio(token, formData).then(res => {
                dispatch(show_alert({
                    status: "success",
                    message: res
                }))
            }).catch(err => {
                setError("Upload Failed")
                dispatch(show_alert({
                    status: "error",
                    message: JSON.stringify(err.message)
                }))
            }).finally(() => {
                setAudioList([])
                getAudioList(testType, audioType)
            })
        })
    }

    useEffect(() => {
        getAudioList(testType, audioType)
        // eslint-disable-next-line
    },[])

    return <div>
        <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xs={6}>
                <Paper className={classes.container}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-label">Test Type</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={testType}
                        onChange={onChangeTestType}
                        >
                            <MenuItem value="TOEFL">{testAlias('TOEFL')}</MenuItem>
                            <MenuItem value="TOAFL">TOAFL</MenuItem>
                            <MenuItem value="INDO">B.INDONESIA</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
            </Grid>
            
            <Grid item lg={6} sm={6} xs={6}>
                <Paper className={classes.container}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-label">Test Type</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={audioType}
                        onChange={onChangeAudioType}
                        >
                            <MenuItem value="questions">QUESTIONS</MenuItem>
                            <MenuItem value="instructions">INSTRUCTION</MenuItem>
                            <MenuItem value="passage">PASSAGE</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
            </Grid>

            <Grid item lg={6} sm={6} xs={6}>
                <Paper className={classes.container} style={{height: 400, overflowY: 'scroll'}}>
                    <h3>File List</h3>
                    <div className={classes.demo}>
                        <List dense={true}>
                        {audioList.map((audio, i) => <ListItem key={i}>
                                <ListItemText
                                    primary={audio}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteAudio(audio, testType, audioType)}>
                                    <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>)}
                        </List>
                    </div>
                </Paper>
            </Grid>

            <Grid item lg={6} sm={6} xs={6}>
                <Paper className={classes.container} style={{height: 400, overflowY: 'scroll'}}>
                    <h3>Uploader</h3>
                    <Dropzone multiple={true} onDrop={acceptedFiles => handleUploadAudio(acceptedFiles, testType, audioType)}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps()} className={classes.dropzone}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop audio file, or click to select files, only support mp3 file</p>
                                {(error != null) && <p style={{color: 'red'}}>Error message here</p>}
                            </div>
                            </section>
                        )}
                    </Dropzone>
                    <br/>
                </Paper>
            </Grid>
        </Grid>
    </div>
}

export default AudioUploader