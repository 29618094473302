import React, {useState} from 'react'
import { Container, Grid, makeStyles, InputLabel, Select, MenuItem, TextField, IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputAdornment, TablePagination } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { faculty, majors } from 'helper/uinmajors'
import EventIcon from '@material-ui/icons/Event';
import Checkbox from '@material-ui/core/Checkbox';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { searchParticipant } from 'model/participantmodel'
import {useDispatch} from 'react-redux'
import { showloader, hideloader } from 'store/actions/loaderActions';
import { show_alert } from 'store/actions/alertActions';
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit';
import { Controller, ErrorMessage, useForm,  } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { editParticipant, deleteParticipant } from 'model/participantmodel'
import {
    show_dialog,
    hide_dialog
} from 'store/actions/dialogActions'
import lang from 'helper/localize'

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
    formControl: {
        width: '100%'
    },
    table: {
        width: '100%'
    }
}));

const ParticipantDatabase = props => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    // PARAMETER SELECT
    const [param, setParam] = useState('name');
    const [openParam, setOpenParam] = useState(false);
    const [paramValue, setParamValue] = useState('')
    const [tableData, setTableData] = useState({})

    const [genderValue, setGenderValue] = useState("")
    const [statusValue, setStatusValue] = useState("student")
    const [programValue, setProgramValue] = useState("")
    const [selectedFaculty, setSelectedFaculty] = useState(0)
    var [uinFaculty, setUinFaculty] = useState("")
    var [uinMajors, setUinMajors] = useState("")
    var [birthdate, setbirthdate] = useState("")
    var participantID = ""
    var [openEdit, setOpenEdit] = useState(false)
    const [participantData, setParticipantData] = useState({
        _id: '',
        nid: '',
        name: '',
        phone: '',
        email: '',
        birthplace: '',
        address: '',
        result: [],
        totalresult: 0,
        exam: {
            examtype: { name: ""}
        }
    })
    const [createSKL, setCreateSKL] = useState(false)

    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [totalData, setTotalData] = useState(0)

    const {register, control, errors, handleSubmit} = useForm()
  
    const handleParamChange = (event) => {
        setParam(event.target.value);
    };
  
    const handleParamClose = () => {
        setOpenParam(false);
    };
  
    const handleParamOpen = () => {
        setOpenParam(true);
    };
    // END OF PARAMETER SELECT

    const handleChangeParamValue = (e) => {
        setParamValue(e.target.value)
    }

    const handleChangeEnter= (e) => {
        if(e.keyCode === 13) {
            setParamValue(e.target.value)
            handleSearch({param, keyword: e.target.value}, {page, limit})
        }
    }

    const handleSearch = ({param, keyword}, {page, limit}) => {
        dispatch(showloader("Loading data..."))
        searchParticipant(token, {param, keyword}, {page, limit}).then(res => {
            setTableData({})
            setTableData(res.data)
            setTotalData(res.totalData)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleAddParticipant(data) {
        let token = localStorage.getItem('token')
        data.faculty = uinFaculty
        data.majors = uinMajors
        data.program = programValue
        data.gender = genderValue
        data.occupation = statusValue
        data.birthdate = birthdate
        data.registrationfee = (statusValue === "general") ? 150000 : 75000
        data.result = [
            data.listening,
            data.structure,
            data.reading
        ]
        data.totalresult = data.ept
        data.createskl = createSKL
        
        dispatch(showloader("Saving data..."))
        setOpenEdit(false)
        editParticipant(token, participantData._id, data).then(res => {
            handleSearch({param, keyword: paramValue}, {page, limit})
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const onFacultyChange = (e) => {
        setSelectedFaculty(faculty.indexOf(e.target.value))
        setUinFaculty(e.target.value)
        setUinMajors(majors[faculty.indexOf(e.target.value)][0])
    }

    const onMajorsChange = (e) => {
        setUinMajors(e.target.value)
    }

    const handleBirthdateChange = e => {
        let dt = moment(e).format("YYYY-MM-DD")
        setbirthdate(dt);
    }

    const handleEditClose = () => {
        setOpenEdit(!openEdit)
    }

    const handleOnChangeLimit = ({target: {value}}) => {
        setLimit(value)
        handleSearch({param, keyword: paramValue}, {page, limit: value})
    }

    const handleOnChangePage = (_, p) => {
        setPage(p)
        handleSearch({param, keyword: paramValue}, {page: p, limit})
    }

    const handleDeleteParticipant = (row) => {
        dispatch(show_dialog({
            title: lang.attention,
            message: lang.deleteconfirm
        }, () => {
            deleteParticipant(row._id, token)
                .then(res => {
                    dispatch(show_alert({
                        status: 'success',
                        message: res.message
                    }))
                }).catch(err => {
                    dispatch(show_alert({
                        status: 'error',
                        message: err.message
                    }))
                }).finally(() => {
                    dispatch(hide_dialog())
                    handleSearch({param, keyword: paramValue}, {page, limit})
                })
        }))
    }

    const handleEditParticipant = (participant) => {
        if (participant.faculty && participant.majors) {
            setSelectedFaculty(faculty.indexOf(participant.faculty))
            setUinFaculty(participant.faculty)

            setUinMajors(majors[faculty.indexOf(participant.faculty)][majors[faculty.indexOf(participant.faculty)].indexOf(participant.majors)])
        }

        if (participant.program) {
            setProgramValue(participant.program)
        }

        setGenderValue(participant.gender)
        setbirthdate(participant.birthdate)
        setParticipantData(participant)
        setOpenEdit(true)
    }

    return <div>
        <Container>
            <Grid container>
                <Grid item lg={2} md={2} sm={2}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Search By</InputLabel>
                        <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={openParam}
                        onClose={handleParamClose}
                        onOpen={handleParamOpen}
                        value={param}
                        onChange={handleParamChange}
                        >
                        <MenuItem value="name">Name</MenuItem>
                        <MenuItem value="nid">NIM/NIK</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item lg={2} md={2} sm={2}>
                    <TextField id="standard-basic" value={paramValue} label="Parameter" onKeyDown={(e) => handleChangeEnter(e)} onChange={(e) => handleChangeParamValue(e)} />
                </Grid>

                <Grid item lg={2} md={2} sm={2}>
                    <IconButton 
                        children={<SearchIcon/>}
                        style={{paddingTop:20}}
                        onClick={() => handleSearch({param, keyword: paramValue}, {page, limit})}
                   />
                </Grid>

                <Grid item lg={6} md={6} sm={6} style={{textAlign: 'right'}}>
                   {/* <IconButton 
                        children={<TodayIcon/>}
                   />
                   <IconButton 
                        children={<ArchiveIcon/>}
                   /> */}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" width={5} padding="checkbox"><DeleteForeverIcon fontSize="small" padding="checkbox"/> </TableCell>
                                <TableCell align="center" width={5}><EditIcon fontSize="small" /> </TableCell>
                                <TableCell>Full Name</TableCell>
                                <TableCell align="right">Birthdate</TableCell>
                                <TableCell align="right">NIM/NIK</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">EXAM</TableCell>
                                <TableCell align="right">Listening</TableCell>
                                <TableCell align="right">Structure</TableCell>
                                <TableCell align="right">Reading</TableCell>
                                <TableCell align="right">Score</TableCell>
                                <TableCell align="right">EXAM Date</TableCell>
                                {/* <TableCell align="right">Edit</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(tableData.length > 0) ? tableData.map((row) => (
                            <TableRow hover key={row._id}>
                                <TableCell size="small" width={5} padding="checkbox">
                                    <IconButton color="primary" aria-label="Edit" size="small" onClick={() => handleDeleteParticipant(row)}>
                                        <DeleteForeverIcon fontSize="small" style={{color: "red"}} />
                                    </IconButton>
                                </TableCell>
                                <TableCell size="small" width={5} padding="checkbox">
                                    <IconButton color="primary" aria-label="Edit" size="small" onClick={() => handleEditParticipant(row)}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{moment(row.birthdate).format("YYYY-MM-DD")}</TableCell>
                                <TableCell align="right">{row.nid}</TableCell>
                                <TableCell align="right">{(row.occupation) ? row.occupation : "-"}</TableCell>
                                <TableCell align="right">{(row.exam) ? (row.exam.examtype.name === "TOEFL") ? "ETIC" : (row.exam.examtype.name === "B.INDONESIA") ? "TIPA" : row.exam.examtype.name : "-"}</TableCell>
                                <TableCell align="right">{(row.result && row.result[0]) ? row.result[0] : 0}</TableCell>
                                <TableCell align="right">{(row.result && row.result[1]) ? row.result[1] : 0}</TableCell>
                                <TableCell align="right">{(row.result && row.result[2]) ? row.result[2] : 0}</TableCell>
                                <TableCell align="right">{(row.totalresult) ? row.totalresult : 0}</TableCell>
                                <TableCell align="right">{(row.exam) ? moment(row.exam.starttime).format("YYYY-MM-DD") : "-"}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                                <TableCell align="center" colSpan={12}>No Data</TableCell>
                            </TableRow>}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[10, 50]}
                            count={totalData}
                            rowsPerPage={limit}
                            page={page}
                            onChangePage={handleOnChangePage}
                            onChangeRowsPerPage={handleOnChangeLimit}
                        />
                    </Table>
                </Grid>
                <Grid item lg={4} md={4} sm={4} style={{display: 'none'}}>
                    <TableContainer  style={{padding:10, marginLeft: -10}}>
                        <fieldset style={{padding:0, marginLeft: 0, marginRight: 5, width: '100%'}}>
                            <legend>ETIC</legend>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Exam</TableCell>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">S</TableCell>
                                        <TableCell align="center">cert</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="1">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                    <TableRow key="2">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                    <TableRow key="3">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </fieldset>
                    </TableContainer>
                    <TableContainer  style={{padding:10, marginLeft: -10}}>
                        <fieldset style={{padding:0, marginLeft: 0, marginRight: 5, width: '100%'}}>
                            <legend>TOAFL</legend>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Exam</TableCell>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">S</TableCell>
                                        <TableCell align="center">cert</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="1">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                    <TableRow key="2">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                    <TableRow key="3">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </fieldset>
                    </TableContainer>
                    <TableContainer  style={{padding:10, marginLeft: -10}}>
                        <fieldset style={{padding:0, marginLeft: 0, marginRight: 5, width: '100%'}}>
                            <legend>B.INDONESIA</legend>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Exam</TableCell>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">S</TableCell>
                                        <TableCell align="center">cert</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="1">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                    <TableRow key="2">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                    <TableRow key="3">
                                        <TableCell component="th" scope="row">
                                        13:00 - 15:00
                                        </TableCell>
                                        <TableCell>2020-05-01</TableCell>
                                        <TableCell align="center">210</TableCell>
                                        <TableCell><CheckCircleIcon color="primary"/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </fieldset>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
        <Dialog open={openEdit} onClose={handleEditClose} aria-labelledby="form-dialog-title" >
            <form onSubmit={handleSubmit(handleAddParticipant)}>
            <DialogTitle id="form-dialog-title">{(participantID !== 0) ? "Edit" : "Add"} Participant</DialogTitle>
                <DialogContent>
                        <FormControl fullWidth className={classes.formControl}>
                            <Controller as={
                                <TextField 
                                    ref={register}
                                    helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                                    label="Name"
                                    placeholder="Input your name"
                                    autoFocus={true}
                                    />
                            } name="name" control={control} defaultValue={participantData.name} rules={{required: "Name is required"}} />
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <Controller as={
                                <TextField 
                                    ref={register}
                                    helperText={(errors.nid) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="nid"/>}
                                    label="NIK"
                                    placeholder="Input your NIK"
                                    />
                            } name="nid" control={control} defaultValue={participantData.nid} rules={{required: "NIK is required"}} />
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup aria-label="gender" name="gender" row value={genderValue} onChange={(e) => setGenderValue(e.target.value)}>
                                <FormControlLabel
                                value="female"
                                control={<Radio color="primary" />}
                                label="Female"
                                labelPlacement="end"
                                />
                                <FormControlLabel
                                value="male"
                                control={<Radio color="primary" />}
                                label="Male"
                                labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <Controller as={
                                <TextField
                                    ref={register}
                                    helperText={(errors.ttl) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthplace"/>}
                                    label="Birthplace"
                                    type="text"
                                    className={classes.textInput}
                                    id="outlined-name"
                                    placeholder="Input your birthplace"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EventIcon style={{color: "#e0e0e0"}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            } name="birthplace" control={control} defaultValue={participantData.birthplace} rules={{required: 'This field is required'}} />
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    value={birthdate}
                                    onChange={handleBirthdateChange}
                                    format="yyyy-MM-dd"
                                    id="date-picker-inline"
                                    label="Birthdate"
                                    placeholder="yyyy-mm-dd"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    helperText={(errors.birthdate) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthdate"/>}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <Controller as={
                                <TextField 
                                    ref={register}
                                    helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                    label="Email"
                                    type="email"
                                    placeholder="Input your email"
                                    />
                            } name="email" control={control} defaultValue={participantData.email} rules={{required: "Email is required"}} />
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <Controller as={
                                <TextField 
                                    ref={register}
                                    helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="phone"/>}
                                    label="Phone Number"
                                    type="number"
                                    placeholder="Input your phone number"
                                    />
                            } name="phone" control={control} defaultValue={participantData.phone} rules={{required: "phone number is required"}} />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <Controller as={
                                <TextField
                                    ref={register}
                                    helperText={(errors.address) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="address"/>}
                                    label="Address"
                                    type="number"
                                    className={classes.textInput}
                                    id="outlined-name"
                                    placeholder="Input your address"
                                    multiline
                                    rows="4"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EditIcon style={{color: "#e0e0e0"}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            } name="address" control={control} defaultValue={participantData.address} rules={{required: 'Address is required'}} />
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup aria-label="status" name="status" row value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                <FormControlLabel
                                value="student"
                                control={<Radio color="primary" />}
                                label="UIN Jakarta Student"
                                labelPlacement="end"
                                />
                                <br/>
                                <FormControlLabel
                                value="employee"
                                control={<Radio color="primary" />}
                                label="UIN Jakarta Teacher/Employee"
                                labelPlacement="end"
                                />
                                <br/>
                                <FormControlLabel
                                value="general"
                                control={<Radio color="primary" />}
                                label="General"
                                labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            (statusValue === "student") && <div>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Program</FormLabel>
                                    <RadioGroup aria-label="program" name="program" row value={programValue} onChange={(e) => setProgramValue(e.target.value)}>
                                        <FormControlLabel
                                        value="s1"
                                        control={<Radio color="primary" />}
                                        label="S1"
                                        labelPlacement="end"
                                        checked={(programValue === "s1") ? true : false}
                                        />
                                        <FormControlLabel
                                        value="s2"
                                        control={<Radio color="primary" />}
                                        label="S2"
                                        labelPlacement="end"
                                        checked={(programValue === "s2") ? true : false}
                                        />
                                        <FormControlLabel
                                        value="s3"
                                        control={<Radio color="primary" />}
                                        label="S3"
                                        labelPlacement="end"
                                        checked={(programValue === "s3") ? true : false}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel id="selectfacultylabel">Faculty</InputLabel>
                                    <Select
                                        defaultChecked={true}
                                        defaultValue={uinFaculty}
                                        value={uinFaculty}
                                        labelId="selectfacultylabel"
                                        id="selectfaculty"
                                        onChange={(e) => onFacultyChange(e)}
                                        >
                                            {faculty.map((v, i) => (
                                                <MenuItem key={i} value={v}>{v}</MenuItem>
                                            ))}
                                    </Select>
                                    
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel id="selectmajorlabel">Majors</InputLabel>
                                        <Select
                                            defaultChecked={true}
                                            defaultValue={uinMajors}
                                            value={uinMajors}
                                            labelId="selectmajorlabel"
                                            id="selectmajor"
                                            onChange={(e) => onMajorsChange(e)}
                                            >
                                                {majors[selectedFaculty].map((v, i) => (
                                                    <MenuItem key={i} value={v}>{v}</MenuItem>
                                                ))}
                                        </Select>
                                </FormControl>
                                
                            </div>
                        }
                       
                        <Grid container spacing={2} style={{marginTop:10}}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <h4>{participantData.exam ? participantData.exam.examtype.name : ''} Score</h4>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3}>
                                <FormControl className={classes.formControl}>
                                    <Controller as={
                                        <TextField 
                                            ref={register}
                                            helperText={(errors.listening) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="listening"/>}
                                            label="Listening Score"
                                            type="number"
                                            placeholder="Input listening score"
                                            />
                                    } name="listening" control={control} defaultValue={participantData.result[0] ? participantData.result[0] : 0} rules={{required: "listening score is required"}} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3}>
                                <FormControl className={classes.formControl}>
                                    <Controller as={
                                        <TextField 
                                            ref={register}
                                            helperText={(errors.structure) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="structure"/>}
                                            label="Structure Score"
                                            type="number"
                                            placeholder="Input structure score"
                                            />
                                    } name="structure" control={control} defaultValue={participantData.result[1] ? participantData.result[1] : 0} rules={{required: "structure score is required"}} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3}>
                                <FormControl className={classes.formControl}>
                                    <Controller as={
                                        <TextField 
                                            ref={register}
                                            helperText={(errors.reading) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="reading"/>}
                                            label="Reading Score"
                                            type="number"
                                            placeholder="Input reading score"
                                            />
                                    } name="reading" control={control} defaultValue={participantData.result[2] ? participantData.result[2] : 0} rules={{required: "reading score is required"}} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3}>
                                <FormControl className={classes.formControl}>
                                    <Controller as={
                                        <TextField 
                                            ref={register}
                                            helperText={(errors.ept) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="ept"/>}
                                            label="Ept Score"
                                            type="number"
                                            placeholder="Input ept score"
                                            />
                                    } name="ept" control={control} defaultValue={participantData.totalresult ? participantData.totalresult : 0} rules={{required: "ept score is required"}} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                            <FormControlLabel
                                    control={<Checkbox color="primary" checked={createSKL} onChange={() => setCreateSKL(!createSKL)} name="createskl" />}
                                    label="Create Skl"
                                />
                            </Grid>
                        </Grid>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleEditClose}>
                    Cancel
                </Button>
                <Button color="primary" type="submit">
                    SAVE
                </Button>
                </DialogActions>
            </form>
        </Dialog>
    </div>
}

export default ParticipantDatabase