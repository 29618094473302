import React, {
    forwardRef,
    useState,
    useEffect
} from 'react'

import {
    Container,
    Grid,
    Paper,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    Tooltip
} from '@material-ui/core'

import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { api } from 'helper/utils'

import { show_alert } from 'store/actions/alertActions'
import { show_dialog, hide_dialog } from 'store/actions/dialogActions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import lang from 'helper/localize'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { deleteParticipant, getRegistrationData, massVerifiedUser, VerifiedUser } from 'model/participantmodel'
import RefreshIcon from '@material-ui/icons/Refresh';
import { hideloader, showloader } from 'store/actions/loaderActions';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      marginRight: 10
    },
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    paper: {
        margin: 'auto',
        overflow: 'hidden',
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
  }));

const RegisterManagement = props => {

    const dispatch = useDispatch()
    const classes = useStyles();
    const token = localStorage.getItem('token')
    const [selectedDate, setSelectedDate] = React.useState(moment().format("YYYY-MM-DD"));
    var user = JSON.parse(localStorage.getItem('auth'))

    const [isTableLoad, setTableLoad] = useState(false)
    const [userData, setUserData] = useState({
        columns: [
            {title: lang.name, field: "name"},
            {title: lang.no_id, field: "nid"},
            {title: lang.phone, field: "phone"},
            {title: lang.email, field: "email"},
            {title: lang.occupation, field: "occupation"},
            {title: lang.faculty, field: "faculty"},
            {title: lang.majors, field: "majors"},
            {title: lang.token, field: "token"}
        ],
        data: []
    })

    const refreshTable = () => {
        setTableLoad(true)
        setUserData({
            columns: [
                {title: lang.name, field: "name"},
                {title: lang.no_id, field: "nid"},
                {title: lang.phone, field: "phone"},
                {title: lang.email, field: "email"},
                {title: lang.occupation, field: "occupation"},
                {title: lang.faculty, field: "faculty"},
                {title: lang.majors, field: "majors"},
                {title: lang.token, field: "token"}
            ],
            data: []
        })
        getRegistrationData(token, selectedDate).then(res => {
            setUserData({
                columns: userData.columns,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            setTableLoad(false)
        })
    }

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format("YYYY-MM-DD"));
    };

    const handleVerified = () => {
        setTableLoad(true)
        dispatch(show_dialog({
            title: lang.attention,
            message: "Are you sure want to verified all participant data at "+moment(selectedDate).format("dddd, Do MMM YYYY")+" ?"
        }, () => {
            dispatch(hide_dialog())
            massVerifiedUser(token, selectedDate).then(res => {
                dispatch(show_alert({
                    status: "success",
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err
                }))
            }).finally(() => {
                setTableLoad(false)
                refreshTable()
            })
        }))
    }

    const handleDownload = () => {
        var url = "participant/download-registration/"+selectedDate
        var win = window.open(api(url), '_self');
        win.focus();
    }

    useEffect(refreshTable, [])

    return <div className={classes.root}>
        <Container maxWidth="lg">
            <Grid container spacing={2} style={{marginTop:20}}>
                <Grid item lg={12} md={12} xs={12}>
                    <Paper className={classes.paper}>
                        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                            <Toolbar>
                                <Grid container spacing={2} alignItems="center" justifyContent="space-between" direction="row">
                                    <Grid item>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Register Date"
                                                format="MM/dd/yyyy"
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={handleDownload}
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            endIcon={<SaveIcon/>}
                                        >
                                            Download
                                        </Button>
                                        <Button
                                            onClick={handleVerified}
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            endIcon={<CheckCircleOutlineIcon/>}
                                        >
                                            Verified
                                        </Button>
                                        <Tooltip title="Reload">
                                            <IconButton onClick={refreshTable}>
                                                <RefreshIcon className={classes.block} color="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <MaterialTable 
                            icons={tableIcons}
                            title={lang.register}
                            columns={userData.columns}
                            data={userData.data}
                            isLoading={isTableLoad}
                            options={{
                            header: true,
                            actionsColumnIndex:0
                            }}
                            actions={[
                                {
                                    icon: tableIcons.Check,
                                    tooltip: 'Verified participant',
                                    disabled: (user.role === "ADMIN" || user.role === "MANAGER") ? false : true,
                                    onClick: (event, rowData) => {
                                        dispatch(show_dialog({
                                            title: lang.attention,
                                            message: lang.formatString(lang.exam_feeconfirm, rowData.name)
                                        }, () => {
                                            dispatch(hide_dialog())
                                            dispatch(showloader(lang.token_activation))
                                            VerifiedUser(rowData._id, token, selectedDate).then(data => {
                                                dispatch(show_alert({
                                                    status: 'success',
                                                    message: data.message
                                                }))
                                                refreshTable()
                                            }).catch(err => {
                                                dispatch(show_alert({
                                                    status: 'error',
                                                    message: err.message
                                                }))
                                            }).finally(() => {
                                                dispatch(hideloader())
                                            })
                                            
                                        }))
                                    }
                                },
                                {
                                    icon: tableIcons.Delete,
                                    tooltip: 'Delete Participant',
                                    onClick: (event, rowData) => {
                                        dispatch(show_dialog({
                                            title: lang.attention,
                                            message: lang.deleteconfirm
                                        }, () => {
                                            dispatch(hide_dialog())
                                            dispatch(showloader("Deleting data..."))
                                            deleteParticipant(rowData._id, token, selectedDate).then(data => {
                                                dispatch(show_alert({
                                                    status: 'success',
                                                    message: data.message
                                                }))
                                                refreshTable()
                                            }).catch(err => {
                                                dispatch(show_alert({
                                                    status: 'error',
                                                    message: err.message
                                                }))
                                            }).finally(() => {
                                                dispatch(hideloader())
                                            })
                                        }))
                                    }
                                }
                            ]}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    </div>
}

export default RegisterManagement