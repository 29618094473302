export const NEXT_QUESTIONS = 'NEXT_QUESTIONS'
export const BACK_QUESTIONS = 'BACK_QUESTIONS'
export const JUMP_QUESTIONS = 'JUMP_QUESTIONS'
export const STEPS_QUESTIONS = 'STEPS_QUESTIONS'
export const SET_STEP = 'SET_STEP'
export const TIMEUP = 'TIMEUP'
export const PROCESS_NEXT = 'PROCESS_NEXT'
export const PROCESS_BACK = 'PROCESS_BACK'
export const PROCESS_JUMP = 'PROCESS_JUMP'
export const RESET = 'RESET'
export const HELP = 'HELP'
export const ANSWER_TIME_UP = "ANSWER_TIME_UP"
export const ANSWER_TIME_RESET = "ANSWER_TIME_RESET"
export const AUDIO_VOLUME = "AUDIO_VOLUME"
export const SHOW_INSTRUCTION = "SHOW_INSTRUCTION"
export const CLOSE_INSTRUCTION = "CLOSE_INSTRUCTION"
export const SET_LISTENING_PART = "SET_LISTENING_PART"
export const LISTENING_PART_B = "LISTENING_PART_B"
export const LISTENING_PART_C = "LISTENING_PART_C"
export const SET_EXAM_TYPE = "SET_EXAM_TYPE"
export const NEXT_LISTENING_PART_QUESTION_STATE = "NEXT_LISTENING_PART_QUESTION_STATE"
export const JUMP_LISTENING_PART_QUESTION_STATE = "JUMP_LISTENING_PART_QUESTION_STATE"
export const EXAM_STEP_CATEGORY = "EXAM_STEP_CATEGORY"

export const next_questions = () => {
    return { type: NEXT_QUESTIONS }
}

export const back_questions = () => {
    return { type: BACK_QUESTIONS }
}

export const jump_questions = (index) => {
    return { type: JUMP_QUESTIONS, index}
}

export const set_step = (step) => {
    return {type: SET_STEP, step }
}

export const next_step = () => {
    return {type: STEPS_QUESTIONS }
}

export const timeup = (newtime) => {
    return {type : TIMEUP, newtime}
}

export const processing_next = () => {
    return {type: PROCESS_NEXT }
}

export const processing_back = () => {
    return {type: PROCESS_BACK }
}

export const processing_jump = (index) => {
    return {type: PROCESS_JUMP, index }
}

export const reset_test = () => {
    return { type: RESET }
}

export const help_exam = () => {
    return {type: HELP }
}

export const start_answer_time = () => {
    return {type: ANSWER_TIME_UP}
}

export const reset_answer_time = (time) => {
    return {type: ANSWER_TIME_RESET, value: time}
}

export const audio_volume = (value) => {
    return {type: AUDIO_VOLUME, value: value}
}

export const show_instruction = (value) => {
    return {type: SHOW_INSTRUCTION, value: value}
}

export const close_instruction = () => {
    return {type: CLOSE_INSTRUCTION}
}

export const set_listeningPart = (value) => {
    return {type: SET_LISTENING_PART, value: value}
}

export const next_listening_part_b = (limit) => {
    return {type: LISTENING_PART_B, max: limit}
}

export const next_listening_part_c = (limit) => {
    return {type: LISTENING_PART_C, max: limit}
}

export const set_exam_type = (examtype) => {
    return {type: SET_EXAM_TYPE, value: examtype}
}

export const next_listening_part_question_state = () => {
    return {type: NEXT_LISTENING_PART_QUESTION_STATE}
}

export const jump_listening_part_question_state = (value) => {
    return {type: JUMP_LISTENING_PART_QUESTION_STATE, value: value}
}

export const set_exam_step_category = (value) => {
    return {type: EXAM_STEP_CATEGORY, value: value}
}