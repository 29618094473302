export default {
    history: {
        EXAM_STATE: "EXAM_STATE",
        EXAM_HISTORY: "EXAM_HISTORY",
    },
    examType: {
        TIPA: "B.INDONESIA",
        ETIC: "TOEFL",
        TOAFL: "TOAFL"
    },
    examIndex: {
        LISTENING: 0,
        STRUCTURE: 1,
        READING: 2
    },
    examStep: {
        LISTENING: "LISTENING",
        STRUCTURE: "STRUCTURE",
        READING: "READING"
    },
    env: {
        PROD: "prod",
        DEV: "dev"
    },
    user_role: {
        ADMIN: "ADMIN",
        MANAGER: "MANAGER",
        INVIGILATOR: "INVIGILATOR",
        QUESTIONER: "QUESTIONER"
    },
    menu: {
        EXAM: "EXAM",
        REGISTRATION: "REGISTRATION",
        QUESTION: "QUESTION",
        ACCOUNT: "ACCOUNT",
        SETTING: "SETTING"
    }
}