import React, {useState, useEffect} from 'react'
import { 
    Paper,
    Container,
    Grid,
    Divider,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Grow,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import {useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reset_test } from 'store/actions/examActions'
import { 
    ind_score,
    ind_secone,
    ind_sectwo,
    ind_secthree,
    eng_score,
    get_section_score,
    eng_secone,
    eng_sectwo,
    eng_secthree,
    arabic_score
} from 'helper/resultmatrix'
import { saveScore } from 'model/exammodel'
import { show_alert } from 'store/actions/alertActions'
import { showloader, hideloader } from 'store/actions/loaderActions'
import lang from 'helper/localize'
import isElectron from 'is-electron'
import { examStepArabic } from 'helper/utils'
import { Alert } from '@material-ui/lab';
import {config} from 'helper/config'
import params from 'helper/params'
import { saveScoreToHistory } from 'helper/examhistory'

const useStyle = makeStyles({
    identifyBox: {
        padding: 16
    },
    table: {
        minWidth: 650,
      },
})

const Result = props => {

    const classes = useStyle()
    const history = useHistory()
    const dispatch = useDispatch()
    const examType = useSelector(state => state.examTypeState)
    const examdata = JSON.parse(localStorage.getItem('examdata'))
    const score = JSON.parse(localStorage.getItem('score'))
    const showtotalscore = false
    const [isCalculating, setIsCalculating] = useState(true)
    const [totalQ, setTotalQ] = useState(0)
    const [isSaved, setIsSaved] = useState(false)

    const handleBack = () => {
        localStorage.removeItem("listening_answer")
        localStorage.removeItem("score")
        localStorage.removeItem("examdata")
        localStorage.removeItem("auth")
        localStorage.removeItem('token')
        dispatch(reset_test())
        history.replace('/exam-auth')
    }

    const calculateResult = () => {       
        let tresult = 0
        let convertScore = []
        let token = examdata.participant.token
        let starttime = examdata.exam_starttime
        let name = examdata.participant.name
        let nik = examdata.participant.no_id

        if(examType === params.examType.TOAFL) {
            convertScore[0] = score[0]
            convertScore[1] = score[1]
            convertScore[2] = score[2]

            tresult = parseInt(arabic_score())
        }
        
        if (examType === params.examType.ETIC) {
            convertScore[0] = eng_secone[score[0]]
            convertScore[1] = eng_sectwo[score[1]]
            convertScore[2] = eng_secthree[score[2]]

            tresult = parseInt(eng_score())
        }

        if (examType === params.examType.TIPA) {
            convertScore[0] = ind_secone[score[0]]
            convertScore[1] = ind_sectwo[score[1]]
            convertScore[2] = ind_secthree[score[2]]

            tresult = parseInt(ind_score())
        }
        
        var historyData = {
            name: name,
            nik: nik,
            examtype: examType,
            listening: convertScore[0],
            structure: convertScore[1],
            reading: convertScore[2],
            totalscore: tresult,
            token: token,
            starttime: starttime
        }

        saveScore(examdata.participant._id, {
            result: convertScore, 
            totalresult: tresult
        }).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
            setIsSaved(true)
            historyData.saved = true
        }).catch(err => {           
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
            setIsSaved(false)
            historyData.saved = false
        }).finally(() => {
            dispatch(hideloader())
            setIsCalculating(false)
            saveScoreToHistory(historyData)
            if(examType === params.examType.TOAFL) {
                setTotalQ(parseInt(arabic_score()))
            }
            
            if(examType === params.examType.ETIC){
                setTotalQ(parseInt(eng_score()))
            }

            if(examType === params.examType.TIPA){
                setTotalQ(parseInt(ind_score()))
            }
        })
    }

    const handleSendEmail = () => {
        dispatch(showloader(lang.send_email_process))
        calculateResult()
    }

    useEffect(() => {
        if(!isElectron() && config.env === params.env.PROD) {
            history.replace('/')
        }else{
            calculateResult()
        }
        // eslint-disable-next-line
    }, [])

    return <div className="App-header">
        <Grow in={true}>
        <Container maxWidth="xs">
            <Container maxWidth="sm">
                <Paper elevation={3} className={classes.identifyBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} align="center">
                            <Alert severity={(isSaved === false) ? "error" : "success"}>
                                {(isSaved === false) ? lang.result_fail_saved : lang.result_saved}
                            </Alert>
                            <p style={{fontSize:14}}>{examdata.participant.name} <br/>{examdata.participant.no_id}</p>
                            { (showtotalscore) && <span>Score: {totalQ}</span> }
                            <Divider/>
                        </Grid>
                        {
                            (isCalculating) ? <Container align="center" style={{padding:10}}><CircularProgress /></Container> : 
                            <Container style={{padding:0}}>
                                <Grid item lg={12} xs={12} sm={12} align="center">
                                    <TableContainer>
                                        <Table aria-label="simple table" size="small" dir={(examType === "TOAFL") ? "rtl" : "ltr"}>
                                            <TableBody>
                                            {examdata.steps.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell component="th" scope="row" align={(examType === "TOAFL") ? "right" : "left"}>
                                                        <span style={{fontSize: 14, fontWeight: 'bold'}}>{(examType === "TOAFL") ? examStepArabic(row) : row}</span>
                                                    </TableCell>
                                                    <TableCell align={(examType === "TOAFL") ? "left" : "right"}>{get_section_score(examType, i)}</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={12} align="center" style={{marginTop:20}}>
                                    <Button variant="contained" color="primary" startIcon={<EmailIcon />} style={{marginLeft:10}} onClick={handleSendEmail}>{lang.send_email}</Button>
                                </Grid>
                            </Container>
                        }
                    </Grid>
                </Paper>
                {(!isCalculating) && <Container align="center">
                    <Button style={{fontSize:12, marginTop:10, color: '#fff'}} onClick={handleBack}>{lang.back_to_home}</Button>
                </Container>}
                
            </Container>
        </Container>
        </Grow>
        
    </div>
}

export default Result