import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { set_submenu_index } from 'store/actions/subMenuAction';
import { hideloader, showloader } from 'store/actions/loaderActions';
import { loginpass } from 'store/actions/userAction';
import { show_alert } from 'store/actions/alertActions';
import lang from 'helper/localize'
import { logout } from 'model/usermodel'
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core'

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const { classes, onDrawerToggle } = props;
  const user = JSON.parse(localStorage.getItem('auth'))
  const token = localStorage.getItem('token')
  const history = useHistory()
  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleTabChange = (e, value) => {
    setTabIndex(value)
    dispatch(set_submenu_index(value))
  }

  const handleMenu = event => {
      setAnchorEl(event.currentTarget)
  };

  let [username, setUsername] = useState("")

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handleProfile = () => {
    history.push('/console/myprofile')
  }

  const handleLogout = () => {
    dispatch(showloader(lang.loggingout))
    logout(token).then(res => {
      dispatch(loginpass())
      localStorage.removeItem('auth')
      localStorage.removeItem('token')
      history.replace('/login')
    }).catch(err => {
      dispatch(show_alert({
          status: 'error',
          message: err.message
      }))
    }).finally(() => {
      dispatch(hideloader())
    })
  }

  useEffect(() => {          
    if(!token && !user) {
      history.replace('/')
    }
    setUsername((user != null) ? user.name : "")
  }, [token, user, history])

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            {/* <Grid item>
              <Link className={classes.link} href="#" variant="body2">
                Go to docs
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item>
              <div>
                <Button color="inherit" className={classes.iconButtonAvatar} startIcon={<AccountCircle />} onClick={handleMenu}>
                  {username}
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={handleClose}
                  open={open}
                >
                  <MenuItem onClick={handleProfile}>
                    <ListItemIcon><AccountBoxIcon/></ListItemIcon> {lang.profile}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon><LockIcon/></ListItemIcon> {lang.logout}
                  </MenuItem>
                </Menu>
              </div>
              
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {props.menu}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button className={classes.button} variant="outlined" color="inherit" size="small">
                Web setup
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      {(props.submenu) && <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={tabIndex} textColor="inherit" onChange={handleTabChange}>
          {props.submenu && props.submenu.map(function (v, i) {
            let component = null
            if (user && v.access.includes(user.role)) {
              component = <Tab key={i} textColor="inherit" label={v.name} />  
            }

            return component
          })}
        </Tabs>
      </AppBar>}
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);