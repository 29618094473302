import React, {useEffect} from 'react'
import Header from 'component/newheader'
import Schedule from 'component/newschedule'

export default function NewHome() {

    useEffect(() => {
        document.body.style.backgroundColor = "#fff";
    })

    return <div id="page-wrapper">
        <Header/>
        <Schedule/>
    </div>
}