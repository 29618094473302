import axios from 'axios'
import {api} from 'helper/utils'

export const getPackets = (token, examtype = '') => {
    return new Promise(function(resolve, reject) {
        let query = (examtype) ? `?exam_type=${examtype}` : ''
        axios.get(api(`packet${query}`), {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data.data)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    });
}

export const savePacket = (token, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`packet`), data, {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    });
}

export const updatePacket = (token, packetId, data) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`packet/${packetId}`), data, {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    });
}

export const deletePacket = (token, packetId) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`packet/${packetId}`), {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    });
}