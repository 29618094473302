import React, {useState, useEffect} from 'react'
import 'App.css'
import { useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Button, Container, Grid, TextField, InputAdornment, makeStyles, Paper, FormControl, InputLabel, Divider, Select, MenuItem, Fade } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {useDispatch} from 'react-redux'
import {forgotPassword, login, verifyEmail, register as regUser} from 'model/usermodel'
import {loginpass} from 'store/actions/userAction'
import {checkAuth} from 'helper/utils'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import {showloader, hideloader} from 'store/actions/loaderActions'
import {show_alert} from 'store/actions/alertActions'
import { Alert, AlertTitle } from '@material-ui/lab';
import lang from 'helper/localize'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
          margin: theme.spacing(1),
          width: 200,
        },
      },

    input: {
        marginBottom: 10,
        padding:16
    },

    backMenu: {
        marginRight: theme.spacing(2),
    },

    textInput: {
        padding:8,
        '& .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '&:hover .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInput-underline:focus': {
            borderBottomColor: 'white',
        },
    },

    identifyBox: {
        padding: 16
    },

    buttontoken: {
        height: 55,
    },

    smalltext: {
        fontSize: 12,
        padding: 0
    },
}))

const Login = props => {

    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [logState, setLogState] = useState("LOGIN")
    const [loginToggle, setToggle] = useState(true)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const { register, handleSubmit, watch, errors, control } = useForm()

    const handleEmail = e => {
        setEmail(e.target.value)
    }
    const handlePassword = e => {
        setPassword(e.target.value)
    }

    const handleToggle = () => {
        setToggle(!loginToggle)
        setLogState((!loginToggle) ? "LOGIN" : "REG")
    }

    const handleLogin = data => {      
        dispatch(showloader(lang.loggingin))
        login(data.email, data.password).then(res => {
            dispatch(loginpass())
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
            localStorage.setItem('token', res.token)
            localStorage.setItem('auth', JSON.stringify(res.user))
            if(res.user.role === "ADMIN" || res.user.role === "MANAGER" || res.user.role === "INVIGILATOR") {
                history.replace('/console')
            }else if(res.user.role === "QUESTIONER"){
                history.replace('/console/questionbank')
            }

        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const handlerRegister = data => {
        dispatch(showloader(lang.process))
        regUser({
            name: data.name,
            password: data.regpassword,
            nid: data.nik,
            role: data.role,
            email: data.regemail
        }).then(res => {
            setLogState("LOGIN")
            setToggle(!loginToggle)
            dispatch(show_alert({
                status: 'success',
                message: res
            }))
        }).catch(err => dispatch(show_alert({
            status: 'error',
            message: err.response.data.message
        }))).finally(() => {
            dispatch(hideloader())
        })
    }

    const handleForgotPassword = data => {
        dispatch(showloader(lang.reset_password))
        forgotPassword(data.forgotemail).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    useEffect(() => {
        if(checkAuth()) {
            history.replace('/console')
        }
        // eslint-disable-next-line
    }, [])

    return <Fade in={true} timeout={500}>
    <div className="App-header">
        <AppBar position="fixed" color="inherit" elevation={0}>
            <Toolbar>
                <Button style={{color: "black"}} className={classes.backMenu} startIcon={<ArrowBackIcon/>} onClick={() => history.replace('/')}> E-TEST</Button>
                <div className={classes.root}></div>
                {
                    (loginToggle) ? 
                        <Button style={{color: "black"}} startIcon={<AccountCircleIcon/>} onClick={handleToggle}> {lang.register}</Button>
                    :
                        <Button style={{color: "black"}} startIcon={<LockIcon/>} onClick={handleToggle}> {lang.login}</Button>
                }

            </Toolbar>
        </AppBar>
        {(logState !== "FORGOT") ? <Container maxWidth="xs">
            <Grid container> 
                {(logState === "LOGIN") && <Grid item sm={12} xs={12}>
                        <form onSubmit={handleSubmit(handleLogin)} key={0}>
                            <Paper className={classes.input}>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            ref={register}
                                            helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                            onChange={handleEmail}
                                            type="email"
                                            className={classes.textInput}
                                            autoFocus={true}
                                            placeholder={lang.input_email}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="email" control={control} defaultValue={email} rules={{required: lang.require_email}}/>

                                </FormControl>
                                <Divider/>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            ref={register}
                                            helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="password"/>}
                                            onChange={handlePassword}
                                            type="password"
                                            className={classes.textInput}
                                            autoFocus={false}
                                            placeholder={lang.input_password}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="password" control={control} defaultValue={password} rules={{required: lang.require_password}} />
                                    
                                </FormControl>
                            </Paper>
                            
                            <Button variant="contained" type="submit" fullWidth={true} color="secondary" >{lang.login}</Button>
                            <Button fullWidth={true} style={{marginTop:10, color:'#fff'}} onClick={() => setLogState("FORGOT")}>{lang.forget_password}</Button>
                        </form>
                    </Grid>
                }
                
                {(logState === "REG") && <Grid item sm={12} xs={12}>
                        <form onSubmit={handleSubmit(handlerRegister)} key={1}>
                            <Paper className={classes.input}>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                                            type="text"
                                            ref={register}
                                            className={classes.textInput}
                                            autoFocus={true}
                                            placeholder={lang.input_name}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircleIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="name" control={control} defaultValue="" rules={{required: lang.require_name}} />
                                </FormControl>
                                <Divider/>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            helperText={(errors.nip) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="nip"/>}
                                            type="text"
                                            ref={register}
                                            className={classes.textInput}
                                            id="outlined-nip"
                                            placeholder={lang.input_nik}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircleIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="nip" control={control} defaultValue="" />
                                </FormControl>
                                <Divider/>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            helperText={(errors.regemail) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="regemail"/>}
                                            type="email"
                                            ref={register}
                                            className={classes.textInput}
                                            placeholder={lang.input_email}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="regemail" control={control} defaultValue="" rules={{required: lang.require_email, validate: async value => await verifyEmail(value) || lang.formatString(lang.exist_email, value)}} />
                                </FormControl>
                                <Divider/>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            helperText={(errors.regpassword) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="regpassword"/>}
                                            type="password"
                                            ref={register}
                                            className={classes.textInput}
                                            placeholder={lang.input_password}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="regpassword" control={control} defaultValue="" rules={{required: lang.require_password}} />
                                </FormControl>
                                <Divider/>
                                <FormControl fullWidth variant="outlined">
                                    <Controller as={
                                        <TextField
                                            helperText={(errors.conpassword) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="conpassword"/>}
                                            type="password"
                                            ref={register}
                                            className={classes.textInput}
                                            placeholder={lang.confirm_password}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon style={{color: "#e0e0e0"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    } name="conpassword" control={control} defaultValue="" rules={{required: lang.require_confirmpassword, validate: value => value === watch('regpassword') || lang.nomatch_confirmpassword}}/>
                                </FormControl>
                                
                                <br/>
                                <FormControl fullWidth={true} variant="filled">
                                    <InputLabel id="label-select-role">ROLE</InputLabel>
                                    <Controller as={
                                        <Select
                                            labelWidth={40}
                                            labelId="label-select-role"
                                            id="select-role"
                                            placeholder="ROLE"
                                            label="Role">
                                                <MenuItem value="MANAGER">MANAGER</MenuItem>
                                                <MenuItem value="INVIGILATOR">INVIGILATOR</MenuItem>
                                                <MenuItem value="QUESTIONER">QUESTION MAKER</MenuItem>
                                        </Select>
                                    } name="role" control={control} defaultValue="QUESTIONER" />
                                </FormControl>
                            </Paper>
                            
                            <Button type="submit" variant="contained" fullWidth={true} color="secondary">REGISTER</Button>
                        </form>
                    </Grid>
                }
            </Grid>
        </Container> 
        : 
        <Container maxWidth="sm">
            <Alert severity="info">
                <AlertTitle>{lang.attention}</AlertTitle>
                {lang.forgetpassword_message}
            </Alert>
            <br/>
            <form key={2} onSubmit={handleSubmit(handleForgotPassword)}>
                <Paper elevation={3} className={classes.identifyBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={10} sm={10}>
                            <FormControl fullWidth className={classes.root.margin} variant="outlined">
                                <Controller as={
                                    <TextField
                                        ref={register}
                                        helperText={(errors.forgotemail) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="forgotemail"/>}
                                        autoFocus={true}
                                        label="Email"
                                        variant="outlined"
                                        placeholder={lang.input_email}
                                    />
                                } name="forgotemail" defaultValue="" control={control} rules={{required: lang.require_email}} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Button variant="contained" color="primary"                                 className={classes.buttontoken} fullWidth={true} type="submit">
                                <EmailIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                
            </form>
            <Grid item lg={12} sm={12} xs={12} >
                <Button color="inherit" type="button" style={{marginTop:20}} onClick={() => setLogState("LOGIN")}>&larr; {lang.back_to_login}</Button>
            </Grid>
        </Container>
        }
    </div>
    </Fade>
}

export default Login