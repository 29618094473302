import React, {useState, useEffect} from 'react'
import { Container, Grid, makeStyles, lighten, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {testAlias} from 'helper/utils'
import { getExamList, generateCertificate } from 'model/exammodel'
import { showloader, hideloader } from 'store/actions/loaderActions'
import { useDispatch } from 'react-redux'
import { show_alert } from 'store/actions/alertActions';
import lang from 'helper/localize'
import { BlobToJSON } from 'helper/utils'

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
  
const headCells = [
    { id: 'examName', numeric: false, disablePadding: true, label: 'Exam Name' },
    { id: 'venue', numeric: false, disablePadding: true, label: 'Venue' },
    { id: 'totalParticipant', numeric: true, disablePadding: false, label: 'Participant' },
    { id: 'certificate', numeric: true, disablePadding: false, label: 'Certificate' },
    { id: 'gendate', numeric: true, disablePadding: false, label: 'Generate Date' }
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map((headCell, i) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, callback } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            EXAM
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Print Certificate">
            <IconButton aria-label="Print Certificate" onClick={() => callback()}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    formControl: {
        width: '100%',
        marginTop: 15
    }
  }));

const CertificateManagement = props => {
    
    function createData(id, examname, venue, totalParticipant, certificate, generateddate) {
      return { id, examname, venue, totalParticipant, certificate, generateddate };
    }

    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    // DATE PICKER
    const [selectedDate, setSelectedDate] = useState(moment());

    const handleDateChange = (date) => {
      console.log(date);
      setSelectedDate(date);
      getExamData(examType, date)
    };
    // END OF DATE PICKER

    // DATA TABLE
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const dense = false
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n, i) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        console.log(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    // END OF DATA TABLE

    // SELECT
    const [examType, setExamType] = useState("TOEFL");
    const [openSelect, setOpenSelect] = React.useState(false);
  
    const handleSelectChange = (event) => {
      setExamType(event.target.value)
      getExamData(event.target.value, selectedDate)
    };
  
    const handleSelectClose = () => {
      setOpenSelect(false);
    };
  
    const handleSelectOpen = () => {
      setOpenSelect(true);
    };
    // END OF SELECT

    const getExamData = (type, examdate) => {
      setRows([])
      dispatch(showloader(lang.loading))
      getExamList(type, moment(examdate).format("YYYY-MM-DD")).then(data => {
        let row = []
        data.forEach((dt, i) => {
          let check = (dt.isGenerate) ? <CheckCircleIcon color="primary"/> : <RadioButtonUncheckedIcon />
          row.push(createData(dt._id, dt.examname, dt.venue.join(', '), dt.totalparticipant, check, dt.generateDate))
        })
        setRows(row)
      }).catch(err => {
        dispatch(show_alert({
          status: "error",
          message: err.message
        }))
      }).finally(() => {
        dispatch(hideloader())
      })
    }

    const handleGenerateCertificate = (data, token) => {
      dispatch(showloader(lang.loading))
      generateCertificate(data, token).then(res => {
        getExamData()
        const file = new Blob([res], {type: 'application/pdf'});

        const fileURL = URL.createObjectURL(file);
        
        window.open(fileURL);
      }).catch(err => {
        BlobToJSON(err, function(result) {
            dispatch(show_alert({
                status: 'error',
                message: JSON.parse(result).message
            }))
        })
      }).finally(() => {
        dispatch(hideloader())
      })
    }

    useEffect(getExamData, [])

    return <div>
        <Container>
            <Grid container>
                <Grid item lg={6} md={6} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date picker dialog"
                            format="yyyy-MM-dd"
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Exam Type</InputLabel>
                        <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={openSelect}
                        onClose={handleSelectClose}
                        onOpen={handleSelectOpen}
                        value={examType}
                        onChange={handleSelectChange}
                        >
                            <MenuItem value="TOEFL">{testAlias('TOEFL')}</MenuItem>
                            <MenuItem value="TOAFL">TOAFL</MenuItem>
                            <MenuItem value="INDO">B.INDONESIA</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={12} md={12} sm={12}>
                    <EnhancedTableToolbar numSelected={selected.length} callback={() => handleGenerateCertificate(selected, token)}/>
                        <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size='small'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            />
                            <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${row.id}`;

                                return (
                                    <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                        {row.examname}
                                    </TableCell>
                                    <TableCell align="right">{row.venue}</TableCell>
                                    <TableCell align="right">{row.totalParticipant}</TableCell>
                                    <TableCell align="right">{row.certificate}</TableCell>
                                    <TableCell align="right">{row.generateddate}</TableCell>
                                    </TableRow>
                                );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                </Grid>
            </Grid>
        </Container>
    </div>
}

export default CertificateManagement