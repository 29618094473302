import params from './params'

/**
 * Save participant exam result history so user can resend the data
 * @param {string} name participant name
 * @param {string} nik participant nik
 * @param {string} examtype TOEFL, TOAFL or B.INDONESIA
 * @param {int} listening listening score
 * @param {int} structure structure score
 * @param {int} reading reading score
 * @param {int} totalscore total score
 * @param {string} token participant exam token
 * @param {string} starttime examstarttime
 */
export const saveScoreToHistory = ({
    name,
    nik,
    examtype,
    listening,
    structure,
    reading,
    totalscore,
    token,
    starttime,
    saved
}, index) => {
    let alldata = []
    let newData = {
        name,
        nik,
        examtype,
        listening,
        structure,
        reading,
        totalscore,
        token,
        starttime,
        saved
    }
    let lastData = loadScoreFromHistory()

    if (index !== undefined) {
        lastData[index] = newData
    }

    if (lastData && lastData.length > 0) {
        if (index === undefined) {
            alldata.push(newData)
        }
        
        lastData.forEach(data => {
            alldata.push(data)
        });
        
        localStorage.setItem(params.history.EXAM_HISTORY, JSON.stringify(alldata))
    } else {
        alldata.push(newData)
        localStorage.setItem(params.history.EXAM_HISTORY, JSON.stringify(alldata))
    }
}

/**
 * load all saved user last exam data
 * only compiled score data
 */
export const loadScoreFromHistory = () => {
    let examlist = localStorage.getItem(params.history.EXAM_HISTORY)
    return JSON.parse(examlist)
}

/**
 * 
 * @param {int} examStep 0 - Listening, 1 - Structure or 2 - Reading
 * @param {string} examType TOEFL, TOAFL or B.INDONESIA
 * @param {int} no question number
 */
export const saveExamStateToHistory = ({
    examStep,
    examType,
    no,
    examData,
    answer,
    score
}) => {
    localStorage.setItem(params.history.EXAM_STATE, JSON.stringify({
        examStep,
        examType,
        no,
        examData,
        answer,
        score
    }))
}

/**
 * Load exam state data so when user have a problem
 * and need to restart the exam will start where it's left of
 */
export const loadExamStateToHistory = () => {
    let examState = localStorage.getItem(params.history.EXAM_STATE)
    return JSON.parse(examState)
}