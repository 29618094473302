import React, {useEffect, useState} from 'react'
import Content from 'template/Content'
import table from 'component/table'
import { Grid, FormControl, InputLabel, Select, MenuItem, makeStyles, Button, ButtonGroup, Dialog, DialogActions, DialogTitle, DialogContent, TextField, InputAdornment } from '@material-ui/core' 
import { getAllExamType } from 'model/settingmodel'
import { useDispatch } from 'react-redux'
import { show_alert } from 'store/actions/alertActions'
import { testAlias } from 'helper/utils'
import { showloader } from 'store/actions/loaderActions'
import lang from 'helper/localize'
import { hideloader } from 'store/actions/loaderActions'
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table'
import { getPackets } from 'model/packetmodel'
import userdata from 'helper/userdata'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import DescriptionIcon from '@material-ui/icons/Description';
import { savePacket } from 'model/packetmodel'
import moment from 'moment'
import { updatePacket } from 'model/packetmodel'
import { deletePacket } from 'model/packetmodel'
import { show_dialog, hide_dialog } from 'store/actions/dialogActions'

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const Packets = props => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { user, token } = userdata

    const [openFormPacket, setOpenFormPacket] = useState(false)
    const [listExamType, setListExamType] = useState([])
    const [selectedExamType, setSelectedExamType] = useState({})
    const [isPacketTableLoad, setPacketTableLoad] = useState(false)
    const [selectedPacket, setSelectedPacket] = useState({})
    const [columnPacket, setcolumnPacket] = useState({
        columns: [
          { title: 'Name', field: 'name'},
          { title: 'Code', field: 'code'},
          { title: 'Listening', field: 'listening_max_no' },
          { title: 'Structure', field: 'structure_max_no' },
          { title: 'Reading', field: 'reading_max_no' },
          { title: 'Created By', field: 'created_by.name'},
          { title: 'Updated', field: 'updatedAt' },
        ],
        data: []
    });

    const {register, handleSubmit, control, errors, setValue } = useForm({
        defaultValues: {
            name: selectedPacket.name ?? '',
            code: selectedPacket.code ?? '',
            listening_max_no: 1,
            structure_max_no: 1,
            reading_max_no: 1,
        }
    })

    const getListExamType = () => {
        dispatch(showloader(lang.loading))
        getAllExamType()
            .then(data => {
                setListExamType(data)
                setSelectedExamType(data[0])

                return data[0]
            })
            .then(data => refreshPacketTable(data._id))
            .catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            })
            .finally(() => dispatch(hideloader()))
    }

    const handleChangeExamType = (e) => {
        let examTypeIndex = listExamType.map(d => d._id).indexOf(e.target.value)
        setSelectedExamType(listExamType[examTypeIndex])
        refreshPacketTable(listExamType[examTypeIndex]._id)
    }

    const refreshPacketTable = (selectedExamTypeId) => {
        setPacketTableLoad(true)
        getPackets(token, selectedExamTypeId)
            .then(data => {
                data.forEach((dt, i) => {
                    let ndt = moment(dt.updatedAt).fromNow()
                    data[i].updatedAt = ndt
                })

                setcolumnPacket({
                    columns: columnPacket.columns,
                    data
                })
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message ?? err
                }))
            }).finally(() => setPacketTableLoad(false))
    }

    const handleToggleFormPacket = () => {
        setOpenFormPacket(!openFormPacket)
    }

    const formPacketTitle = (Object.keys(selectedPacket).length === 0) ? `Add Packet ${testAlias(selectedExamType.name ?? '')}` : `Update Packet ${testAlias(selectedExamType.name ?? '')}`;

    const handleFormPacket = (data, e) => {
        data.created_by = user._id
        data.exam_type = selectedExamType._id

        setOpenFormPacket(false)
        dispatch(showloader(lang.process))
        if (Object.keys(selectedPacket).length === 0) {
            savePacket(token, data)
            .then(data => {
                dispatch(show_alert({
                    status: 'success',
                    message: data.message
                }))

                refreshPacketTable(selectedExamType._id)
            })
            .catch(err => {
                dispatch(show_alert({
                    status: 'success',
                    message: err.message ?? err
                }))
            })
            .finally(() => dispatch(hideloader()))
        } else {
            updatePacket(token, selectedPacket._id, data)
            .then(data => {
                dispatch(show_alert({
                    status: 'success',
                    message: data.message
                }))

                refreshPacketTable(selectedExamType._id)
            })
            .catch(err => {
                dispatch(show_alert({
                    status: 'success',
                    message: err.message ?? err
                }))
            })
            .finally(() => dispatch(hideloader()))
        }
    }

    useEffect(getListExamType, []);

    return <div><Content isPaper={true}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" direction="row">
            <Grid item>
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-exam-type-label">Exam Type</InputLabel>
                    <Select
                    labelId="select-exam-type-label"
                    id="select-exam-type"
                    value={selectedExamType._id ?? ''}
                    onChange={handleChangeExamType}
                    label="Exam Type"
                    >
                        {listExamType.map((v, i) => <MenuItem value={v._id} key={i}>
                            <em>{testAlias(v.name)}</em>
                        </MenuItem>)}
                        
                    </Select>
                </FormControl>
            </Grid>

            <Grid item>
                <ButtonGroup disableElevation   variant="contained" color="primary">
                    <Button
                        startIcon={<RefreshIcon />}
                        onClick={() => refreshPacketTable(selectedExamType._id)}
                    >{lang.refresh}</Button>
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSelectedPacket({})
                            setOpenFormPacket(true)
                        }}
                    >{lang.add}</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
        <MaterialTable
            icons={table.icons}
            title=""
            columns={columnPacket.columns}
            data={columnPacket.data}
            isLoading={isPacketTableLoad}
            options={{
                header: true,
                actionsColumnIndex:7
            }}
            actions={[
                {
                    icon: table.icons.Edit,
                    tooltip: lang.edit,
                    onClick: (event, rowData) => {
                        setOpenFormPacket(true)
                        setSelectedPacket(rowData)
                        setValue("name", rowData.name)
                        setValue("code", rowData.code)
                        setValue("listening_max_no", rowData.listening_max_no)
                        setValue("structure_max_no", rowData.structure_max_no)
                        setValue("reading_max_no", rowData.reading_max_no)
                    }
                },
                {
                    icon: table.icons.Delete,
                    tooltip: lang.delete,
                    onClick: (event, rowData) => {
                        dispatch(show_dialog({
                            title: lang.attention,
                            message: lang.deleteconfirm
                        }, () => {
                            dispatch(hide_dialog())
                            dispatch(showloader(lang.process))
                            deletePacket(token, rowData._id)
                                .then(res => {
                                    dispatch(show_alert({
                                        status: 'success',
                                        message: res.message
                                    }))

                                    refreshPacketTable(selectedExamType._id)
                                }).catch(err => {
                                    dispatch(show_alert({
                                        status: 'error',
                                        message: err.message ?? err
                                    }))
                                }).finally(() => {
                                    dispatch(hideloader())
                                })
                        }))
                    }
                }
            ]}
        />
    </Content>
    <Dialog open={openFormPacket} onClose={handleToggleFormPacket}aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit(handleFormPacket)}>
        <DialogTitle id="form-dialog-title">{formPacketTitle}</DialogTitle>
        <DialogContent>
            <FormControl fullWidth variant="outlined">
                <Controller as={
                    <TextField
                        ref={register({name: 'name'}, {required: 'Name is required'})}
                        helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                        label="Name"
                        type="text"
                        id="outlined-name"
                        placeholder="Input packet Name"
                        InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <DescriptionIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    } name="name" control={control} defaultValue={selectedPacket.name ?? ''}
                />      
            </FormControl>
            <FormControl fullWidth variant="outlined">
                <Controller as={
                    <TextField
                        ref={register({name: 'code'}, {required: 'Code is required'})}
                        helperText={(errors.code) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="code"/>}
                        label="Code"
                        type="text"
                        id="outlined-code"
                        placeholder="Input code"
                        InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <DescriptionIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    } name="code" control={control} defaultValue={selectedPacket.code ?? ''}
                />
            </FormControl>
            <FormControl fullWidth variant="outlined">
                <Controller as={
                    <TextField
                        ref={register({name: 'listening_max_no'}, {
                            required: 'Listening maximum question is required',
                            min: {
                                value: 1,
                                message: 'Listening minimum question is 1'
                            }
                        })}
                        helperText={(errors.code) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="listening_max_no"/>}
                        label="Listening Maximum question"
                        type="number"
                        min={1}
                        id="outlined-listening_max_no"
                        placeholder="Input maximum question for listening"
                        InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <DescriptionIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    } name="listening_max_no" control={control} defaultValue={selectedPacket.listening_max_no ?? 1}
                />
            </FormControl>
            <FormControl fullWidth variant="outlined">
                <Controller as={
                    <TextField
                        ref={register({name: 'structure_max_no'}, {
                            required: 'Structure maximum question is required',
                            min: {
                                value: 1,
                                message: 'Structure minimum question is 1'
                            }
                        })}
                        helperText={(errors.code) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="structure_max_no"/>}
                        label="Structure Maximum question"
                        type="number"
                        min={1}
                        id="outlined-structure_max_no"
                        placeholder="Input maximum question for structure"
                        InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <DescriptionIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    } name="structure_max_no" control={control} defaultValue={selectedPacket.structure_max_no ?? 1}
                />
            </FormControl>
            <FormControl fullWidth variant="outlined">
                <Controller as={
                    <TextField
                        ref={register({name: 'reading_max_no'}, {
                            required: 'Reading maximum question is required',
                            min: {
                                value: 1,
                                message: 'Reading minimum question is 1'
                            }
                        })}
                        helperText={(errors.code) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="reading_max_no"/>}
                        label="Reading Maximum question"
                        type="number"
                        min={1}
                        id="outlined-reading_max_no"
                        placeholder="Input maximum question for reading"
                        InputProps={{
                            startAdornment: (
                                    <InputAdornment position="start">
                                        <DescriptionIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    } name="reading_max_no" control={control} defaultValue={selectedPacket.reading_max_no ?? 1}
                />
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFormPacket(false)} color="primary">
            {lang.cancel}
          </Button>
          <Button type="submit" color="primary">
            {lang.save}
          </Button>
        </DialogActions>
        </form>
    </Dialog>
    </div>
}

export default Packets