import { 
    QuestionsReducers, 
    StepsReducers, 
    ProcessingReducers,
    TimeReducers,
    HelpReducers,
    AnswerTimeReducers,
    ListeningAudioReducers,
    InstructionPageReducers,
    ListeningPartReducers,
    ListeningPartBReducers,
    ListeningPartCReducers,
    ExamTypeReducers,
    ListeningPartQuestionStateReducers,
    ExamStepCategoryReducers
} from './examReducers'
import {
    LoaderReducers,
    DialogReducers,
    AlertReducers,
    SubMenuReducers
} from './appReducers'
import {
    LoginReducers
} from './userReducers'

import {RESET} from 'store/actions/examActions'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
    questions: QuestionsReducers,
    examState: StepsReducers,
    processExam: ProcessingReducers,
    globalLoader: LoaderReducers,
    globalDialog: DialogReducers,
    timeState: TimeReducers,
    isLogin: LoginReducers,
    globalAlert: AlertReducers,
    examHelp: HelpReducers,
    listeningAnswer: AnswerTimeReducers,
    listeningVolume: ListeningAudioReducers,
    instructionPage: InstructionPageReducers,
    listeningPart: ListeningPartReducers,
    listeningPartBIndex: ListeningPartBReducers,
    listeningPartCIndex: ListeningPartCReducers,
    examTypeState: ExamTypeReducers,
    listeningPartQuestionState: ListeningPartQuestionStateReducers,
    examStepCategory: ExamStepCategoryReducers,
    subMenuState: SubMenuReducers
})

const appReducers = (state, action) => {
    (action.type === RESET) && (state = undefined)
    
    return allReducers(state, action)
}

export default appReducers