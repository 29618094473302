import {config} from './config'

export const api = (url) => {
    return `${config.apihost}${config.apiroot}${url}`;
}

export const baseURL = (url) => {
    return `${config.audio_base_url}${url}`
}

export function checkAuth() {
    const token = localStorage.getItem('token')
    const auth = localStorage.getItem('auth')
    if(token && auth) {
        return true
    }else{
        return false
    }
}

export function fisherYates(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function testAlias(testname) {
    switch (testname) {
        case "TOEFL":
            return "ETIC"
        case "B.INDONESIA":
            return "TIPA"
        default:
            return testname
    }
}

export const examStepArabic = (step) => {
    switch (step) {
        case "LISTENING":
            return "فهم المسموع";
        case "STRUCTURE":
            return "فهم التراكيب والعبارات";
        case "READING":
            return "فهم المقروء";
        default:
            break;
    }
}

export const BlobToJSON = (blob, callback) => {
    const file = new Blob([blob], {type: 'application/json'});
    let reader = new FileReader();

    reader.onload = function() {
        callback(this.result)
    };

    reader.readAsText(file);
}