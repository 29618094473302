import React, {useEffect, useCallback, useState} from 'react'
import './App.css'
import {BrowserRouter as Router, 
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Identification from './pages/frontend/Identification'
import Exam from './pages/frontend/exam'
import Result from './pages/frontend/result'
import { useSelector, useDispatch } from 'react-redux'
import FullLoader from './component/fullpageloader'
import FullDialog from './component/fulldialog'
import InstructionPage from './component/instructionPage'
import { hide_dialog } from './store/actions/dialogActions'
import NewHome from './pages/frontend/newhome'
import Login from './pages/frontend/login'
import Certificate from './pages/frontend/certificate'
import Registertest from './pages/frontend/registertest'
import Rescheduletest from './pages/frontend/reschedule'
import Dashboard from './template/Paperbase'
import { checkAuth } from './helper/utils'
import FullAlert from './component/fullalert'
import {hide_alert} from './store/actions/alertActions'
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import { idID } from '@material-ui/core/locale'
import { close_instruction } from './store/actions/examActions'

import ConsoleRoute from './consoleroute'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import lang from './helper/localize'

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: amber,
    error: red
  }
}, idID);

function App() {
  const loader = useSelector(state => state.globalLoader)
  const dialog = useSelector(state => state.globalDialog)
  const alert = useSelector(state => state.globalAlert)
  const inspage = useSelector(state => state.instructionPage)
  const dispatch = useDispatch()
  const [, updateState] = useState()
  const forceState = useCallback(() => updateState({}), [])

  useEffect(() => {
    lang.setLanguage('en')
    forceState()
    document.body.style.backgroundColor = "#fff";
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={NewHome}/>
          <Route path="/exam-auth" component={Identification}/>
          <Route path="/exam" component={Exam}/>
          <Route path="/result" component={Result}/>
          <Route path="/login" component={Login}/>
          <Route path="/certificate" component={Certificate}/>
          <Route path="/register" component={Registertest}/>
          <Route path="/reschedule" component={Rescheduletest}/>

          {ConsoleRoute.map((mr, key) => {
              return <Route key={mr.path} exact={true} path={mr.path} render={ () =>                 
                (checkAuth()) ? <Dashboard component={mr.component} route={mr.path} menu={mr.menu} submenu={mr.submenu} /> : <Redirect to="/login" />
              }/>
          })}
          <Route path="*" component={NewHome}/>
        </Switch>
      </Router>
      <FullLoader isopen={loader.isopen} message={loader.message}/>
      <FullDialog isopen={dialog.isopen} title={dialog.title} message={dialog.message} handleClose={() => {
        dispatch(hide_dialog())
      }} handleOk={dialog.handleok} />
      <FullAlert isopen={alert.isopen} message={(alert.data) ? alert.data.message : ""} status={(alert.data) ? alert.data.status : "info" } handleClose={() => {dispatch(hide_alert({status: alert.data.status, message: alert.data.message}))}} />
      <InstructionPage isopen={inspage.isopen} handleClose={() => dispatch(close_instruction())} instruction={inspage.instruction} question={inspage.question} answer={inspage.answer} options={inspage.options} closeable={inspage.closeable} rtl={inspage.rtl} />
    </ThemeProvider>
  );
}

export default App;
