import React from 'react'
import {
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    header: {
        position: 'relative',
		background: 'white',
		borderBottom: 'solid 1px #ccc',
		padding: 0,
		textAlign: 'center',
    },
    container: {
        margin: '0 auto',
		maxWidth: '100%',
        width: '70em',
        '@media screen and (max-width: 1680px)': {
            width: '70em'
		},
		'@media screen and (max-width: 1280px)': {
            width: 'calc(100% - 60px)'
		},
		'@media screen and (max-width: 980px)': {
            width: 'calc(100% - 80px)'
		},
		'@media screen and (max-width: 736px)': {
			width: 'calc(100% - 40px)'
		}
    },
    "row": {
        "display": "flex",
		"flexWrap": "wrap",
		"boxSizing": "borderBox",
		"alignItems": "stretch",
        "padding": "50px 0 0 50px"
    },
    "row_gtr_uniform________last_child": {
        "marginBottom": "0"
    },
    "row_aln_left": {
        "justifyContent": "flex-start"
    },
    "row_aln_center": {
        "justifyContent": "center"
    },
    "row_aln_right": {
        "justifyContent": "flex-end"
    },
    "row_aln_top": {
        "alignItems": "flex-start"
    },
    "row_aln_middle": {
        "alignItems": "center"
    },
    "row_aln_bottom": {
        "alignItems": "flex-end"
    },
    "row____imp": {
        "order": "-1"
    },
    "row____col_1": {
        "width": "8.33333%"
    },
    "row____off_1": {
        "marginLeft": "8.33333%"
    },
    "row____col_2": {
        "width": "16.66667%"
    },
    "row____off_2": {
        "marginLeft": "16.66667%"
    },
    "row____col_3": {
        "width": "25%"
    },
    "row____off_3": {
        "marginLeft": "25%"
    },
    "row____col_4": {
        "width": "33.33333%"
    },
    "row____off_4": {
        "marginLeft": "33.33333%"
    },
    "row____col_5": {
        "width": "41.66667%"
    },
    "row____off_5": {
        "marginLeft": "41.66667%"
    },
    "row____col_6": {
        "width": "50%"
    },
    "row____off_6": {
        "marginLeft": "50%"
    },
    "row____col_7": {
        "width": "58.33333%"
    },
    "row____off_7": {
        "marginLeft": "58.33333%"
    },
    "row____col_8": {
        "width": "66.66667%"
    },
    "row____off_8": {
        "marginLeft": "66.66667%"
    },
    "row____col_9": {
        "width": "75%"
    },
    "row____off_9": {
        "marginLeft": "75%"
    },
    "row____col_10": {
        "width": "83.33333%"
    },
    "row____off_10": {
        "marginLeft": "83.33333%"
    },
    "row____col_11": {
        "width": "91.66667%"
    },
    "row____off_11": {
        "marginLeft": "91.66667%"
    },
    "row____col_12": {
        "width": "100%"
    },
    "row____off_12": {
        "marginLeft": "100%"
    },
    "row_gtr_0": {
        "padding": "0px 0 0 0px"
    },
    "row_gtr_0_gtr_uniform": {
        "paddingTop": "0px"
    },
    "row_gtr_25": {
        "padding": "12.5px 0 0 12.5px"
    },
    "row_gtr_25_gtr_uniform": {
        "paddingTop": "12.5px"
    },
    "row_gtr_50": {
        "padding": "25px 0 0 25px"
    },
    "row_gtr_50_gtr_uniform": {
        "paddingTop": "25px"
    },
    "row_gtr_uniform": {
        "paddingTop": "50px"
    },
    "row_gtr_150": {
        "padding": "75px 0 0 75px"
    },
    "row_gtr_150_gtr_uniform": {
        "paddingTop": "75px"
    },
    "row_gtr_200": {
        "padding": "100px 0 0 100px"
    },
    "row_gtr_200_gtr_uniform": {
        "paddingTop": "100px"
    },
    "@media screen and (max-width: 1680px)": {
        "__expression__": "screen and (max-width: 1680px)",
        "row": {
        "padding": "50px 0 0 50px"
        },
        "row_gtr_uniform________last_child": {
        "marginBottom": "0"
        },
        "row_aln_left": {
        "justifyContent": "flex-start"
        },
        "row_aln_center": {
        "justifyContent": "center"
        },
        "row_aln_right": {
        "justifyContent": "flex-end"
        },
        "row_aln_top": {
        "alignItems": "flex-start"
        },
        "row_aln_middle": {
        "alignItems": "center"
        },
        "row_aln_bottom": {
        "alignItems": "flex-end"
        },
        "row____imp_xlarge": {
        "order": "-1"
        },
        "row____col_1_xlarge": {
        "width": "8.33333%"
        },
        "row____off_1_xlarge": {
        "marginLeft": "8.33333%"
        },
        "row____col_2_xlarge": {
        "width": "16.66667%"
        },
        "row____off_2_xlarge": {
        "marginLeft": "16.66667%"
        },
        "row____col_3_xlarge": {
        "width": "25%"
        },
        "row____off_3_xlarge": {
        "marginLeft": "25%"
        },
        "row____col_4_xlarge": {
        "width": "33.33333%"
        },
        "row____off_4_xlarge": {
        "marginLeft": "33.33333%"
        },
        "row____col_5_xlarge": {
        "width": "41.66667%"
        },
        "row____off_5_xlarge": {
        "marginLeft": "41.66667%"
        },
        "row____col_6_xlarge": {
        "width": "50%"
        },
        "row____off_6_xlarge": {
        "marginLeft": "50%"
        },
        "row____col_7_xlarge": {
        "width": "58.33333%"
        },
        "row____off_7_xlarge": {
        "marginLeft": "58.33333%"
        },
        "row____col_8_xlarge": {
        "width": "66.66667%"
        },
        "row____off_8_xlarge": {
        "marginLeft": "66.66667%"
        },
        "row____col_9_xlarge": {
        "width": "75%"
        },
        "row____off_9_xlarge": {
        "marginLeft": "75%"
        },
        "row____col_10_xlarge": {
        "width": "83.33333%"
        },
        "row____off_10_xlarge": {
        "marginLeft": "83.33333%"
        },
        "row____col_11_xlarge": {
        "width": "91.66667%"
        },
        "row____off_11_xlarge": {
        "marginLeft": "91.66667%"
        },
        "row____col_12_xlarge": {
        "width": "100%"
        },
        "row____off_12_xlarge": {
        "marginLeft": "100%"
        },
        "row_gtr_0": {
        "padding": "0px 0 0 0px"
        },
        "row_gtr_0_gtr_uniform": {
        "paddingTop": "0px"
        },
        "row_gtr_25": {
        "padding": "12.5px 0 0 12.5px"
        },
        "row_gtr_25_gtr_uniform": {
        "paddingTop": "12.5px"
        },
        "row_gtr_50": {
        "padding": "25px 0 0 25px"
        },
        "row_gtr_50_gtr_uniform": {
        "paddingTop": "25px"
        },
        "row_gtr_uniform": {
        "paddingTop": "50px"
        },
        "row_gtr_150": {
        "padding": "75px 0 0 75px"
        },
        "row_gtr_150_gtr_uniform": {
        "paddingTop": "75px"
        },
        "row_gtr_200": {
        "padding": "100px 0 0 100px"
        },
        "row_gtr_200_gtr_uniform": {
        "paddingTop": "100px"
        }
    },
    "@media screen and (max-width: 1280px)": {
        "__expression__": "screen and (max-width: 1280px)",
        "row": {
        "padding": "30px 0 0 30px"
        },
        "row_gtr_uniform________last_child": {
        "marginBottom": "0"
        },
        "row_aln_left": {
        "justifyContent": "flex-start"
        },
        "row_aln_center": {
        "justifyContent": "center"
        },
        "row_aln_right": {
        "justifyContent": "flex-end"
        },
        "row_aln_top": {
        "alignItems": "flex-start"
        },
        "row_aln_middle": {
        "alignItems": "center"
        },
        "row_aln_bottom": {
        "alignItems": "flex-end"
        },
        "row____imp_large": {
        "order": "-1"
        },
        "row____col_1_large": {
        "width": "8.33333%"
        },
        "row____off_1_large": {
        "marginLeft": "8.33333%"
        },
        "row____col_2_large": {
        "width": "16.66667%"
        },
        "row____off_2_large": {
        "marginLeft": "16.66667%"
        },
        "row____col_3_large": {
        "width": "25%"
        },
        "row____off_3_large": {
        "marginLeft": "25%"
        },
        "row____col_4_large": {
        "width": "33.33333%"
        },
        "row____off_4_large": {
        "marginLeft": "33.33333%"
        },
        "row____col_5_large": {
        "width": "41.66667%"
        },
        "row____off_5_large": {
        "marginLeft": "41.66667%"
        },
        "row____col_6_large": {
        "width": "50%"
        },
        "row____off_6_large": {
        "marginLeft": "50%"
        },
        "row____col_7_large": {
        "width": "58.33333%"
        },
        "row____off_7_large": {
        "marginLeft": "58.33333%"
        },
        "row____col_8_large": {
        "width": "66.66667%"
        },
        "row____off_8_large": {
        "marginLeft": "66.66667%"
        },
        "row____col_9_large": {
        "width": "75%"
        },
        "row____off_9_large": {
        "marginLeft": "75%"
        },
        "row____col_10_large": {
        "width": "83.33333%"
        },
        "row____off_10_large": {
        "marginLeft": "83.33333%"
        },
        "row____col_11_large": {
        "width": "91.66667%"
        },
        "row____off_11_large": {
        "marginLeft": "91.66667%"
        },
        "row____col_12_large": {
        "width": "100%"
        },
        "row____off_12_large": {
        "marginLeft": "100%"
        },
        "row_gtr_0": {
        "padding": "0px 0 0 0px"
        },
        "row_gtr_0_gtr_uniform": {
        "paddingTop": "0px"
        },
        "row_gtr_25": {
        "padding": "7.5px 0 0 7.5px"
        },
        "row_gtr_25_gtr_uniform": {
        "paddingTop": "7.5px"
        },
        "row_gtr_50": {
        "padding": "15px 0 0 15px"
        },
        "row_gtr_50_gtr_uniform": {
        "paddingTop": "15px"
        },
        "row_gtr_uniform": {
        "paddingTop": "30px"
        },
        "row_gtr_150": {
        "padding": "45px 0 0 45px"
        },
        "row_gtr_150_gtr_uniform": {
        "paddingTop": "45px"
        },
        "row_gtr_200": {
        "padding": "60px 0 0 60px"
        },
        "row_gtr_200_gtr_uniform": {
        "paddingTop": "60px"
        }
    },
    "@media screen and (max-width: 980px)": {
        "__expression__": "screen and (max-width: 980px)",
        "row": {
        "padding": "40px 0 0 40px"
        },
        "row_gtr_uniform________last_child": {
        "marginBottom": "0"
        },
        "row_aln_left": {
        "justifyContent": "flex-start"
        },
        "row_aln_center": {
        "justifyContent": "center"
        },
        "row_aln_right": {
        "justifyContent": "flex-end"
        },
        "row_aln_top": {
        "alignItems": "flex-start"
        },
        "row_aln_middle": {
        "alignItems": "center"
        },
        "row_aln_bottom": {
        "alignItems": "flex-end"
        },
        "row____imp_medium": {
        "order": "-1"
        },
        "row____col_1_medium": {
        "width": "8.33333%"
        },
        "row____off_1_medium": {
        "marginLeft": "8.33333%"
        },
        "row____col_2_medium": {
        "width": "16.66667%"
        },
        "row____off_2_medium": {
        "marginLeft": "16.66667%"
        },
        "row____col_3_medium": {
        "width": "25%"
        },
        "row____off_3_medium": {
        "marginLeft": "25%"
        },
        "row____col_4_medium": {
        "width": "33.33333%"
        },
        "row____off_4_medium": {
        "marginLeft": "33.33333%"
        },
        "row____col_5_medium": {
        "width": "41.66667%"
        },
        "row____off_5_medium": {
        "marginLeft": "41.66667%"
        },
        "row____col_6_medium": {
        "width": "50%"
        },
        "row____off_6_medium": {
        "marginLeft": "50%"
        },
        "row____col_7_medium": {
        "width": "58.33333%"
        },
        "row____off_7_medium": {
        "marginLeft": "58.33333%"
        },
        "row____col_8_medium": {
        "width": "66.66667%"
        },
        "row____off_8_medium": {
        "marginLeft": "66.66667%"
        },
        "row____col_9_medium": {
        "width": "75%"
        },
        "row____off_9_medium": {
        "marginLeft": "75%"
        },
        "row____col_10_medium": {
        "width": "83.33333%"
        },
        "row____off_10_medium": {
        "marginLeft": "83.33333%"
        },
        "row____col_11_medium": {
        "width": "91.66667%"
        },
        "row____off_11_medium": {
        "marginLeft": "91.66667%"
        },
        "row____col_12_medium": {
        "width": "100%"
        },
        "row____off_12_medium": {
        "marginLeft": "100%"
        },
        "row_gtr_0": {
        "padding": "0px 0 0 0px"
        },
        "row_gtr_0_gtr_uniform": {
        "paddingTop": "0px"
        },
        "row_gtr_25": {
        "padding": "10px 0 0 10px"
        },
        "row_gtr_25_gtr_uniform": {
        "paddingTop": "10px"
        },
        "row_gtr_50": {
        "padding": "20px 0 0 20px"
        },
        "row_gtr_50_gtr_uniform": {
        "paddingTop": "20px"
        },
        "row_gtr_uniform": {
        "paddingTop": "40px"
        },
        "row_gtr_150": {
        "padding": "60px 0 0 60px"
        },
        "row_gtr_150_gtr_uniform": {
        "paddingTop": "60px"
        },
        "row_gtr_200": {
        "padding": "80px 0 0 80px"
        },
        "row_gtr_200_gtr_uniform": {
        "paddingTop": "80px"
        }
    },
    "@media screen and (max-width: 736px)": {
        "__expression__": "screen and (max-width: 736px)",
        "row": {
        "padding": "20px 0 0 20px"
        },
        "row_gtr_uniform________last_child": {
        "marginBottom": "0"
        },
        "row_aln_left": {
        "justifyContent": "flex-start"
        },
        "row_aln_center": {
        "justifyContent": "center"
        },
        "row_aln_right": {
        "justifyContent": "flex-end"
        },
        "row_aln_top": {
        "alignItems": "flex-start"
        },
        "row_aln_middle": {
        "alignItems": "center"
        },
        "row_aln_bottom": {
        "alignItems": "flex-end"
        },
        "row____imp_small": {
        "order": "-1"
        },
        "row____col_1_small": {
        "width": "8.33333%"
        },
        "row____off_1_small": {
        "marginLeft": "8.33333%"
        },
        "row____col_2_small": {
        "width": "16.66667%"
        },
        "row____off_2_small": {
        "marginLeft": "16.66667%"
        },
        "row____col_3_small": {
        "width": "25%"
        },
        "row____off_3_small": {
        "marginLeft": "25%"
        },
        "row____col_4_small": {
        "width": "33.33333%"
        },
        "row____off_4_small": {
        "marginLeft": "33.33333%"
        },
        "row____col_5_small": {
        "width": "41.66667%"
        },
        "row____off_5_small": {
        "marginLeft": "41.66667%"
        },
        "row____col_6_small": {
        "width": "50%"
        },
        "row____off_6_small": {
        "marginLeft": "50%"
        },
        "row____col_7_small": {
        "width": "58.33333%"
        },
        "row____off_7_small": {
        "marginLeft": "58.33333%"
        },
        "row____col_8_small": {
        "width": "66.66667%"
        },
        "row____off_8_small": {
        "marginLeft": "66.66667%"
        },
        "row____col_9_small": {
        "width": "75%"
        },
        "row____off_9_small": {
        "marginLeft": "75%"
        },
        "row____col_10_small": {
        "width": "83.33333%"
        },
        "row____off_10_small": {
        "marginLeft": "83.33333%"
        },
        "row____col_11_small": {
        "width": "91.66667%"
        },
        "row____off_11_small": {
        "marginLeft": "91.66667%"
        },
        "row____col_12_small": {
        "width": "100%"
        },
        "row____off_12_small": {
        "marginLeft": "100%"
        },
        "row_gtr_0": {
        "padding": "0px 0 0 0px"
        },
        "row_gtr_0_gtr_uniform": {
        "paddingTop": "0px"
        },
        "row_gtr_25": {
        "padding": "5px 0 0 5px"
        },
        "row_gtr_25_gtr_uniform": {
        "paddingTop": "5px"
        },
        "row_gtr_50": {
        "padding": "10px 0 0 10px"
        },
        "row_gtr_50_gtr_uniform": {
        "paddingTop": "10px"
        },
        "row_gtr_uniform": {
        "paddingTop": "20px"
        },
        "row_gtr_150": {
        "padding": "30px 0 0 30px"
        },
        "row_gtr_150_gtr_uniform": {
        "paddingTop": "30px"
        },
        "row_gtr_200": {
        "padding": "40px 0 0 40px"
        },
        "row_gtr_200_gtr_uniform": {
        "paddingTop": "40px"
        }
    },
    "ul_dates": {
        "listStyle": "none",
        "paddingLeft": "0"
      },
      "ul_dates_li": {
        "position": "relative",
        "borderTop": "solid 1px #ddd",
        "padding": "1.3em 0 1.3em 6.75em",
        "&:firstChild":{
            "borderTop": "0",
            "paddingTop": "0"
        }
      },
      "ul_dates__date": {
        "display": "block",
        "position": "absolute",
        "left": "0",
        "top": "1.3em",
        "backgroundColor": "var(--date-bg)",
        "height": "3.13em",
        "textAlign": "center",
        "color": "#fff",
        "lineHeight": "1em",
        "borderTopLeftRadius": "5px",
        "borderBottomLeftRadius": "5px",
        "padding": "0.5em 0.75em 0 1em",
        "&:after":{
            "content": "''",
            "position": "absolute",
            "bottom": "0",
            "right": "-1.2em",
            "borderLeft": "solid 1.25em var(--date-bg)",
            "borderTop": "solid 1.8em transparent",
            "borderBottom": "solid 1.8em transparent"
        },
        "&:firstChild":{
            top: 0
        }
      },
      "ul_dates__date_strong": {
        "display": "block",
        "fontSize": "1.75em",
        "paddingTop": "0.15em"
      },
      "ul_dates_h3": {
        "fontSize": "1.1em"
      },
      "ul_dates_p": {
        "margin": "0"
      },
      "footer": {
        "position": "relative",
        "color": "black",
        "padding": "0"
      },
      "footer_a": {
        "color": "#c5c4c4"
      },
      "footer_h2": {
        "color": "#fff"
      },
      "footer_h3": {
        "color": "#fff"
      },
      "footer_h4": {
        "color": "#fff"
      },
      "footer_h5": {
        "color": "#fff"
      },
      "footer_h6": {
        "color": "#fff"
      },
      "footer_strong": {
        "color": "#fff"
      },
      "footer_b": {
        "color": "#fff"
      },
      "footer_h2_a": {
        "color": "inherit"
      },
      "footer_h3_a": {
        "color": "inherit"
      },
      "footer_h4_a": {
        "color": "inherit"
      },
      "footer_h5_a": {
        "color": "inherit"
      },
      "footer_h6_a": {
        "color": "inherit"
      },
      "footer_strong_a": {
        "color": "inherit"
      },
      "footer_b_a": {
        "color": "inherit"
      },
      "footer_ul_contact_li": {
        "borderTopColor": "rgba(255, 255, 255, 0.05)"
      },
      "footer_ul_dates_li": {
        "borderTopColor": "rgba(255, 255, 255, 0.05)"
      },
      "footer_ul_divided_li": {
        "borderTopColor": "rgba(255, 255, 255, 0.05)"
      },
      "footer_header": {
        "margin": "0 0 3em 0"
      },
      "footer_header_h2": {
        "fontSize": "1.75em"
      },
      "copyright": {
        "textAlign": "center",
        "margin": "3em 0 2em 0"
      },
      "copyright_ul_links": {
        "display": "inline-block",
        "background": "#2C2929",
        "borderRadius": "5px",
        "color": "white",
        "margin": "0",
        "padding": "0.85em 2.25em 0.85em 2.25em"
      },
    loginButton: {
        marginTop: 10,
        color: 'white',
        backgroundColor: '#69a2ec',
        '&:hover': {
            backgroundColor: '#003366',
        }
    }
  }));

const NewSchedule = () => {

    const classes = useStyles()

    return <section className={classes.footer}>
        
        <div className={classes.container}>
            <div className={classes.row} style={{padding:"0 4em"}}>
                <div className={classes.row____col_12}>
                    <div className={classes.copyright}>
                        <ul className={classes.copyright_ul_links}>
                            <li style={{listStyle:'none',lineHeight: 1.5}}>&copy; Kampus II UIN Jakarta<br/>Jl. Kertamukti No. 5 Ciputat 15419 Kota Tangerang Selatan, Banten 15419<br/>Telp/Fax: +62 21 7493727 | Email: pusatbahasa@uinjkt.ac.id <br/>Youtube : Ppb UIN Syarif Hidayatullah Jakarta</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default NewSchedule