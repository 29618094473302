import {
    SHOW_LOADER,
    HIDE_LOADER
} from 'store/actions/loaderActions'

import {
    SHOW_DIALOG,
    HIDE_DIALOG
} from 'store/actions/dialogActions'

import {
    SHOW_ALERT,
    HIDE_ALERT
} from 'store/actions/alertActions'

import {
    SET_SUBMENU_INDEX
} from 'store/actions/subMenuAction'

export const LoaderReducers = (state = {isopen: false, message: ""}, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return state = {isopen:true, message: action.message}
        case HIDE_LOADER:
            return state = {isopen:false, message: ""}
        default:
            return state
    }
}

export const DialogReducers = (state = {isopen: false, title: "", message: "", handleok: () => {} }, action) => {
    switch (action.type) {
        case SHOW_DIALOG:
            return state = {
                isopen: true,
                title: action.title,
                message: action.message,
                handleok: action.handleok
            }
        case HIDE_DIALOG:
            return state = {
                isopen: false
            }
        default:
            return state
    }
}

export const AlertReducers = (state = {isopen: false, data: {}}, action) => {
    switch (action.type) {
        case SHOW_ALERT:
            return state = {
                isopen: true,
                data: action.data
            }
        case HIDE_ALERT:
            return state = {
                isopen: false,
                data: action.data
            }
        default:
            return state
    }
}

export const SubMenuReducers = (state = 0, action) => {
    switch (action.type) {
        case SET_SUBMENU_INDEX:
            return state = action.index
        default:
            return state
    }
}