import axios from 'axios'
import {api} from 'helper/utils'

export const getAllExamType = () => {
    return new Promise(function(resolve, reject) {
        axios.get(api("examtype")).then(res => {
            if(res.status === 200) {
                resolve(res.data.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const addExamType = (token, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("examtype"), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const editExamType = (token, id, data) => {
    return new Promise(function(resolve, reject){
        axios.patch(api(`examtype/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const deleteExamType = (token, id) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`examtype/${id}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const getAllVenue = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api("venue"), {
            headers: { 'auth-token': token }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const addVenue = (token, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("venue"), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const editVenue = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`venue/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const deleteVenue = (token, id) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`venue/${id}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}

export const getGeneralSettings = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api("settings"), {
            headers: { 'auth-token': token }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data.message : err))
    })
}