import axios from 'axios'
import {api} from 'helper/utils'

export const getAllParticipant = (token, examid) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant?exam=${examid}`), {
            headers: {'auth-token' : token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const activateToken = (data, token) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`participant/activate/${data.id}`), {
            status: "ACTIVE",
            email: data.email
        }, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteParticipant = (id, token, date) => {
    return new Promise(function(resolve, reject) {
        let dateQuery = ""
        if (date !== undefined) {
            dateQuery = "?date="+dateQuery
        }
        axios.delete(api(`participant/${id}${dateQuery}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200){
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const checkToken = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant/checktoken/${token}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const checkCertificate = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant/checkcertificate/${token}`)).then(res => {
            if(res.status === 200){
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const importParticipant = (token, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`participant/import`), data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const filterParticipant = (token, param) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant?${param['key']}=${param['value']}`), {
            headers: {'auth-token' : token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const editParticipant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`participant/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getRegistrationData = (token, date) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant/registration-data/${date}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const massVerifiedUser = (token, date) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`participant/verified`), {today: date}, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const VerifiedUser = (id, token, date) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`participant/verified/${id}`), {today: date}, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const searchParticipant = (token, {param, keyword}, {page, limit}) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant/search?${param}=${keyword}&page=${page}&limit=${limit}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const saveScoreFromHistory = ({
    listening,
    structure,
    reading,
    totalscore,
    token
}) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`participant/save-score-from-history`), {
            listening,
            structure,
            reading,
            totalscore,
            token
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const streamSKN = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`participant/stream-skn/${token}`), {
            responseType: 'blob'
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            } else {

                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const rescheduleParticipant = (data, token) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`participant/${data.id}/reschedule`), {
            examid: data.examid
        }, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}