import React, {
    useState,
    useEffect,
    forwardRef
} from 'react'
import {
    Container,
    Grid,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    ListItemIcon,
    Paper,
    TextField,
    Button,
    Radio,
    IconButton
} from '@material-ui/core'
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {
    getQuestions,
    deleteQuestion,
    addQuestion,
    editQuestion as doEdit,
    importQuestion
} from 'model/questionmodel'
import moment from 'moment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';  
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {
    useDispatch,
    useSelector
} from 'react-redux'
import {
    show_dialog,
    hide_dialog
} from 'store/actions/dialogActions'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close';
import readingTemplate from 'public/question_template/reading_template.xlsx'
import structureTemplate from 'public/question_template/structure_template.xlsx'
import {
    useForm,
    Controller,
    ErrorMessage
} from 'react-hook-form'
import {
    showloader,
    hideloader
} from 'store/actions/loaderActions';
import {
    show_alert
} from 'store/actions/alertActions';
import renderHTML from 'react-render-html'
import lang from 'helper/localize'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import AudioUploader from 'component/audioUploader'
import {
    testAlias
} from 'helper/utils'
import QuestionInstruction from 'pages/admin-panel/questions/components/questionInstructionComponent'
import IndicatorComponent from 'pages/admin-panel/questions/components/indicatorComponent';
import Packets from './components/packets';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    formControl: {
        marginBottom:10
    },
    tab: {
        backgroundColor: '#009688'
    },
    radio:{
        '& .MuiFormControl-root': {
            width: '100%',
            marginBottom:10
        }
    },
    dropzone: {
        marginTop:10,
        justifyContent:'center',
        alignContent: 'center',
        height:200,
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        padding:32
    },
    formHeader: {
        marginBottom:10,
        backgroundColor: '#37474f',
        color: '#fff'
    },
    arabicFont: {
        fontSize: 28,
        fontWeight: "bold"
    }
}));

const QuestionBank = props => {
    const classes = useStyles();
    const [editQuestion, setEditQuestion] = useState({
        id: "",
        question: "",
        options: ["","","",""],
        rightanswer:"",
        passage: "",
        audiotext: "",
        steps: ""
    })
    const {register, handleSubmit, errors, control} = useForm()
    const [testType, setTestType] = useState("TOEFL")
    const [testSection, setTestSection] = useState("LISTENING")
    const [isTableLoad, setIsTableLoad] = useState(true)
    const [showForm, setShowFrom] = useState(false)
    const [rightanswer, setRightAnswer] = useState(0)
    const tabIndex = useSelector(state => state.subMenuState)
    const handleRightanswer = event => {
        setEditQuestion({
            ...editQuestion,
            rightanswer: editQuestion.options[event.target.value]
        })
        setRightAnswer(event.target.value)
    }

    const dispatch = useDispatch()

    const onChangeTestType = e => {
        setTestType(e.target.value)
        refreshTable(e.target.value, testSection)
    }

    const onChangeTestSection = e => {        
        setTestSection(e.target.value)
        refreshTable(testType, e.target.value)
    }

    const refreshTable = (tid, step) => {
        setIsTableLoad(true)
        setColumn({
            columns: [
            { title: 'Questions', field: 'questions' },
            { title: 'Steps', field: 'steps' },
            { title: 'Created At', field: 'created_at' },
            { title: 'Creator', field: 'qmaker.name' },
            ],
            data: []
        })
        getQuestions(tid, step).then(data => {
                
            data.forEach((dt, i) => {
            let ndt = moment(dt.created_at, "YYYY-MM-DD H:i:s").fromNow()
            data[i].created_at = ndt
            data[i].questions = (data[i].steps === "LISTENING") ? data[i].audio : data[i].questions         
            })

            setColumn({columns: column.columns, data})
            
        }).catch(err => dispatch(show_alert({
            status: "error",
            message: err.message
        }))).finally(() => setIsTableLoad(false))
    }

    useEffect(() => {
      refreshTable(testType, testSection)
      // eslint-disable-next-line
    }, [])

    const [column, setColumn] = useState({
        columns: [
          { title: 'Questions', field: 'questions' },
          { title: 'Steps', field: 'steps' },
          { title: 'Created At', field: 'created_at' },
          { title: 'Creator', field: 'qmaker.name' },
        ],
        data: []
    });

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const handleSubmitForm = data => {
        let token = localStorage.getItem('token')
        let userdata = JSON.parse(localStorage.getItem('auth'))
        let ans = [data.optionA, data.optionB, data.optionC, data.optionD]
        if(editQuestion.id !== "") {
            dispatch(showloader("Processing Update..."))
            doEdit(editQuestion.id, {
                steps: testSection,
                questions: data.question,
                options: ans,
                rightanswer: ans[rightanswer],
                audio: (data.audiotext) ? data.audiotext : "",
                passage: (data.passage) ? data.passage : "",
                qmaker: userdata._id
            }, token).then(res => {
                refreshTable(testType, testSection)
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
                setEditQuestion({
                    id: "",
                    question: "",
                    options: ["","","",""],
                    rightanswer:"",
                    passage: "",
                    audiotext: "",
                    steps: ""
                })
                setShowFrom(false)
            })
        }else{
            dispatch(showloader("Processing Input..."))
            addQuestion({
                steps: testSection,
                questions: data.question,
                options: ans,
                rightanswer: ans[rightanswer],
                audio: (data.audiotext) ? data.audiotext : "",
                passage: (data.passage) ? data.passage : "",
                qmaker: userdata._id
            }, token).then(res => {
                refreshTable(testType, testSection)
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
                setEditQuestion({
                    id: "",
                    question: "",
                    options: ["","","",""],
                    rightanswer:"",
                    passage: "",
                    audiotext: "",
                    steps: ""
                })
                setShowFrom(false)
            })
        }
    }

    const handleImportQuestion = file => {
        let token = localStorage.getItem('token')
        dispatch(showloader("Processing Question..."))
        let formData = new FormData()
        formData.append('file', file[0])
        importQuestion(token, testType, testSection, formData).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => { 
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <div>
        <Container maxWidth="lg" style={{marginTop:10}}>
            {(tabIndex === 0) && <IndicatorComponent />}

            {(tabIndex === 1) && <QuestionInstruction />}

            {(tabIndex === 2) && <Packets />}

            {(tabIndex === 4) && <Grid container style={{marginTop:10}} spacing={2}>
                <Grid container spacing={2} style={{
                    paddingLeft: 8,
                    paddingRight: 8
                }}>
                    <Grid item lg={6} sm={12} xs={12} >
                      <Paper style={{padding:15}}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="demo-simple-select-label">Test Type</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={testType}
                            onChange={onChangeTestType}
                            disabled={(showForm === true) ? true : false}
                            >
                                <MenuItem value="TOEFL">{testAlias('TOEFL')}</MenuItem>
                                <MenuItem value="TOAFL">TOAFL</MenuItem>
                                <MenuItem value="B_INDONESIA">B.INDONESIA</MenuItem>
                            </Select>
                        </FormControl>
                      </Paper>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <Paper style={{padding:15}}>
                          <FormControl className={classes.formControl} fullWidth>
                              <InputLabel id="demo-simple-select-label">Test Section</InputLabel>
                              <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={testSection}
                              onChange={onChangeTestSection}
                              disabled={(showForm === true) ? true : false}
                              >
                                  <MenuItem value="LISTENING">LISTENING</MenuItem>
                                  <MenuItem value="STRUCTURE">STRUCTURE</MenuItem>
                                  <MenuItem value="READING">READING</MenuItem>
                              </Select>
                          </FormControl>
                        </Paper>
                    </Grid>
                    {(showForm) ? 
                    <Grid item lg={12} sm={12} xs={12}>
                      <Paper>
                          <Grid container className={classes.formHeader}>
                            <Grid item lg={6} xs={6} sm={6} style={{paddingLeft:20}}>
                              <h1 style={{lineHeight:1}}>
                                {lang.questions_form} <br/><small style={{fontSize:14}}>{testSection}</small> 
                              </h1>
                            </Grid>
                            <Grid item lg={6} xs={6} sm={6} style={{textAlign:'right'}}>
                                <IconButton onClick={() => setShowFrom(!showForm)} style={{margin:15}}>
                                  <CloseIcon style={{color:'#fff'}}/>
                                </IconButton>
                            </Grid>
                          </Grid>
                          
                          <Container fullwidth="true">
                            <Grid container>                      
                              <Grid item lg={6} sm={6} xs={12} style={{paddingRight: 30}}>
                                <h3>{lang.import_from_excel}</h3>
                                {/* (testSection === "LISTENING") ? true : false */}
                                <Dropzone multiple={false} disabled={false} onDrop={acceptedFiles => handleImportQuestion(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                  <section>
                                    <div {...getRootProps()} className={classes.dropzone}>
                                      <input {...getInputProps()} />
                                      <p>{lang.drag_drop_click}</p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                                <br/>
                                  {
                                    <a href={(testSection === "FAHMULMAQRU") ? readingTemplate : structureTemplate} download={`${testSection}_template.xlsx`}>{lang.download_template}</a>
                                  }
                              </Grid>
                              <Grid item lg={6} sm={6} xs={12} style={{borderLeft: '1px solid #e0e0e0', paddingLeft:30, paddingBottom:20}} dir={(testType === "TOAFL") ? "rtl" : "ltr"}>
                                <form onSubmit={handleSubmit(handleSubmitForm)}>
                                  {
                                    (testSection === "LISTENING") &&
                                    <FormControl fullWidth className={classes.formControl}>
                                      <Controller as={
                                        <TextField 
                                          ref={register}
                                          helperText={(errors.audiotext) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="audiotext"/>}
                                          label="Audio" 
                                          multiline 
                                          placeholder="input audio text" 
                                          autoFocus 
                                        />
                                      } name="audiotext" control={control} defaultValue={editQuestion.audiotext} rules={{required:'audiotext is required'}}/>
                                    </FormControl>
                                  }
                                  {
                                    (testSection === "FAHMULMAQRU") &&
                                    <FormControl fullWidth className={classes.formControl}>
                                      <Controller as={
                                        <TextField 
                                          ref={register}
                                          helperText={(errors.passage) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="passage"/>}
                                          label="Passage" 
                                          multiline 
                                          placeholder="input passage" 
                                          autoFocus/>
                                      } name="passage" control={control} defaultValue={editQuestion.passage} rules={{required: 'Passage is required'}}/>
                                    </FormControl>
                                  }
                                  <FormControl fullWidth className={classes.formControl}>
                                    <Controller as={
                                      <TextField 
                                        ref={register}
                                        helperText={(errors.question) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="question"/>}
                                        label={lang.label_questions} 
                                        multiline 
                                        placeholder={lang.input_questions}/>
                                    } name="question" control={control} defaultValue={editQuestion.question} rules={{required: lang.require_questions}}/>
                                  </FormControl>
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td width="10%">
                                            <Radio
                                              color="primary"
                                              checked={rightanswer === 0 || (editQuestion.rightanswer === editQuestion.options[0] && editQuestion.rightanswer !== "")}
                                              onChange={handleRightanswer}
                                              value={0}
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'A' }}
                                            />
                                          </td>
                                          <td>
                                            <FormControl fullWidth className={classes.formControl}>
                                              <Controller as={
                                                <TextField 
                                                  ref={register}
                                                  helperText={(errors.optionA) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="optionA"/>}
                                                  placeholder={lang.placeholder_options} 
                                                  label={lang.formatString(lang.label_options, 'A')}
                                                  value={editQuestion.options[0]}
                                                  />
                                              } name="optionA" control={control} defaultValue={editQuestion.options[0]} rules={{required: lang.formatString(lang.required_options, 'A')}} />
                                            </FormControl>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10%">
                                            <Radio
                                              color="primary"
                                              checked={rightanswer === 1 || (editQuestion.rightanswer === editQuestion.options[1] && editQuestion.rightanswer !== "")}
                                              onChange={handleRightanswer}
                                              value={1}
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'B' }}
                                            />
                                          </td>
                                          <td>
                                            <FormControl fullWidth className={classes.formControl}>
                                            <Controller as={
                                                <TextField 
                                                  ref={register}
                                                  helperText={(errors.optionB) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="optionB"/>}
                                                  placeholder={lang.placeholder_options} 
                                                  label={lang.formatString(lang.label_options, 'B')}
                                                  value={editQuestion.options[1]}
                                                  />
                                              } name="optionB" control={control} defaultValue={editQuestion.options[1]} rules={{required: lang.formatString(lang.require_questions, 'B')}} />
                                            </FormControl>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10%">
                                            <Radio
                                              color="primary"
                                              checked={rightanswer === 2 || (editQuestion.rightanswer === editQuestion.options[2] && editQuestion.rightanswer !== "")}
                                              onChange={handleRightanswer}
                                              value={2}
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'C' }}
                                            />
                                          </td>
                                          <td>
                                            <FormControl fullWidth className={classes.formControl}>
                                              <Controller as={
                                                <TextField 
                                                  ref={register}
                                                  helperText={(errors.optionC) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="optionC"/>}
                                                  placeholder={lang.placeholder_options} 
                                                  label={lang.formatString(lang.label_options, 'C')}
                                                  value={editQuestion.options[2]}
                                                  />
                                              } name="optionC" control={control} defaultValue={editQuestion.options[2]} rules={{required: lang.formatString(lang.require_questions, 'C')}} />
                                            </FormControl>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10%">
                                            <Radio
                                              color="primary"
                                              checked={rightanswer === 3 || (editQuestion.rightanswer === editQuestion.options[3] && editQuestion.rightanswer !== "")}
                                              onChange={handleRightanswer}
                                              value={3}
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'D' }}
                                            />
                                          </td>
                                          <td>
                                            <FormControl fullWidth className={classes.formControl}>
                                              <Controller as={
                                                  <TextField 
                                                    ref={register}
                                                    helperText={(errors.optionD) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="optionD"/>}
                                                    placeholder={lang.placeholder_options} 
                                                    label={lang.formatString(lang.label_options, 'D')}
                                                    value={editQuestion.options[3]}
                                                    />
                                                } name="optionD" control={control} defaultValue={editQuestion.options[3]} rules={{required: lang.formatString(lang.required_options, 'D')}} />
                                            </FormControl>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  
                                  <Button fullWidth color="primary" variant="contained" type="submit">SAVE</Button>
                                </form>
                              </Grid>
                            </Grid>
                          </Container>
                      </Paper>
                    </Grid>
                    : 
                    <Grid item lg={12} sm={12} xs={12}>
                        <MaterialTable
                            icons={tableIcons}
                            title={`${testSection.toLowerCase()}`}
                            columns={column.columns}
                            data={column.data}
                            isLoading={isTableLoad}
                            options={{
                              header: true,
                              actionsColumnIndex:4
                            }}
                            actions={[
                              {
                                icon: tableIcons.Add,
                                tooltip: 'Add Question',
                                isFreeAction: true,
                                onClick: (event) => {
                                  setRightAnswer(0)
                                  setEditQuestion({
                                    id: "",
                                    question: "",
                                    options: ["","","",""],
                                    rightanswer:"",
                                    passage: "",
                                    audiotext: "",
                                    steps: ""
                                  })
                                  setShowFrom(!showForm)
                                }
                              },
                              {
                                icon: tableIcons.Edit,
                                tooltip: 'Edit Question',
                                onClick: (event, rowData) => {
                                  setEditQuestion({
                                    id: rowData._id,
                                    question: rowData.questions,
                                    options: rowData.options,
                                    rightanswer: rowData.rightanswer,
                                    passage: (rowData.passage === null) ? "" : rowData.passage,
                                    audiotext: (rowData.audio === null) ? "" : rowData.audio,
                                    steps: rowData.steps
                                  })
                                  setShowFrom(true)
                                  setRightAnswer(rowData.options.indexOf(rowData.rightanswer))
                                }
                              },
                              {
                                icon: tableIcons.Delete,
                                tooltip: 'Delete Question',
                                onClick: (event, rowData) => dispatch(show_dialog({
                                  title: lang.warning,
                                  message: lang.deleteconfirm
                                }, () => {
                                  let token = localStorage.getItem('token')
                                  setIsTableLoad(true)
                                  dispatch(hide_dialog())
                                  deleteQuestion(rowData._id, token).then(res => {
                                    dispatch(show_alert({
                                      status: 'success',
                                      message: res.message
                                    }))
                                    setColumn(prevState => {
                                      const data = [...prevState.data];
                                      data.splice(data.indexOf(rowData), 1);
                                      return { ...prevState, data };
                                    })
                                  }).catch(err => dispatch(show_alert({
                                    status: "error",
                                    message: err.message
                                  }))).finally(() => {
                                    setIsTableLoad(false)
                                  })
                                }))
                              }
                            ]}
                            detailPanel={rowData => {
                              return (
                                <div dir={(testType === "TOAFL") ? "rtl": "ltr"}>
                                  <Container align={(testType === "TOAFL") ? "right" : "left"}>
                                    <br/>
                                    <p className={(testType === "TOAFL") ? classes.arabicFont : undefined}>{(rowData.passage) && rowData.passage}</p>
                                    {(rowData.audio) && rowData.audio}
                                    <h1>Question:</h1>
                                    <p className={(testType === "TOAFL") ? classes.arabicFont : undefined}>{(rowData.questions) ? renderHTML(rowData.questions) : "-"}</p>
                                    <h1>Answer:</h1>  
                                    <List dense>
                                      {rowData.options.map((ans, i) => 
                                        <ListItem key={i}>
                                          {(ans === rowData.rightanswer) ? 
                                            <ListItemIcon>
                                              <CheckCircleIcon color="primary" />
                                            </ListItemIcon> : 
                                            <ListItemIcon>
                                              <RadioButtonUncheckedIcon color="primary" />
                                            </ListItemIcon>
                                            }
                                            <span className={(testType === "TOAFL") ? classes.arabicFont : undefined}>{(ans != null) ? ans : "-"}</span>
                                        </ListItem>  
                                      )}
                                    </List>
                                  </Container>
                                </div>
                              )
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                            />
                    </Grid>}
                </Grid>  
            </Grid>}

            {(tabIndex === 5) && <Grid container style={{marginTop:10}} spacing={2}>
                <Grid container spacing={2} style={{
                    paddingLeft: 8,
                    paddingRight: 8
                }}>
                    <Grid item lg={12} sm={12} xs={12}>
                        <AudioUploader/>
                    </Grid>
                </Grid>
            </Grid>}
        </Container>
    </div>
}

export default QuestionBank