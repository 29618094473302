export const faculty = [
    "Fakultas Ilmu Tarbiyah dan Keguruan",
    "Fakultas Adab dan Humaniora",
    "Fakultas Ushuluddin",
    "Fakultas Syariah dan Hukum",
    "Fakultas Ilmu Dakwah dan Ilmu Komunikasi",
    "Fakultas Dirasat Islamiyah",
    "Fakultas Psikologi",
    "Fakultas Ekonomi dan Bisnis",
    "Fakultas Sains dan Teknologi",
    "Fakultas Ilmu Kesehatan",
    "Fakultasi Ilmu Sosial dan Ilmu Politik",
    "Fakultas Kedokteran",
    "Sekolah Pascasarjana"
]

export const majors = [
    [
        "Pendidikan Agama Islam",
        "Pendidikan Bahasa Arab",
        "Pendidikan Bahasa Inggris",
        "Pendidikan IPS",
        "Pendidikan Matematika",
        "Pendidikan Biologi",
        "Pendidikan Fisika",
        "Pendidikan Kimia",
        "Manajemen Pendidikan",
        "Pendidikan Bahasa dan Sastra Indonesia",
        "Pendidikan Guru MI/SD",
        "Pendidikan Islam Anak Usia Dini (PIAUD)",
        "Magister Pendidikan Bahasa Inggris",
        "Magister Pendidikan Agama Islam",
        "Magister Pendidikan Bahasa Arab",
        "Magister Manajemen Pendidikan Islam"
    ],
    [
        "Bahasa dan Sastra Arab",
        "Sastra Inggris",
        "Tarjamah",
        "Ilmu Perpustakaan",
        "Magister Bahasa dan Sastra Arab",
        "Magister Sejarah dan Peradaban Islam"
    ],
    [
        "Studi Agama-Agama",
        "Ilmu Alquran dan Tafsir",
        "Ilmu Hadis",
        "Aqidah dan Filsafat Islam",
        "Ilmu Tasawuf"
    ],
    [
        "Perbandingan Mazhab",
        "Hukum Keluarga Islam (Akhwal Syakhsiyyah)",
        "Hukum Tata Negara (Siyasah)",
        "Hukum Pidana Islam (Jinayah)",
        "Hukum Ekonomi Syariah (Muamalat)",
        "Ilmu Hukum",
        "Magister Hukum Ekonomi Syariah",
        "Magister Hukum Keluarga Islam"
    ],
    [
        "Komunikasi dan Penyiaran Islam",
        "Bimbingan Penyuluhan Islam",
        "Manajemen Dakwah",
        "Pengembangan Masyarakat Islam",
        "Kesejahteraan Sosial",
        "Jurnalistik",
        "Magister Komunikasi dan Penyiaran Islam"
    ],
    [
        "Dirasat Islamiyah",
        "Magister Dirasat Islamiyah"
    ],
    [
        "Psikologi",
        "Magister Psikologi"
    ],
    [
        "Manajemen",
        "Akuntansi",
        "Ekonomi Pembangunan",
        "Perbankan Syariah",
        "Ekonomi Syariah",
        "Magister Perbankan Syariah",
        "Doktor Perbankan Syariah"
    ],
    [
        "Teknik Informatika",
        "Agribisnis",
        "Sistem Informasi",
        "Matematika",
        "Biologi",
        "Kimia",
        "Fisika",
        "Teknik Pertambangan",
        "Magister Agribisnis"
    ],
    [
        "Kesehatan Masyarakat",
        "Farmasi",
        "Ilmu Keperawatan"
    ],
    [
        "Sosiologi",
        "Ilmu Politik",
        "Ilmu Hubungan Internasional"
    ],
    [
        "Kedokteran"
    ],
    [
        "Jurnalistik",
        "Pendidikan Bahasa Arab",
        "Magister Pengkajian Islam",
        "Doktor Pengkajian Islam"
    ]
]