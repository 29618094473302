import React from 'react'
import { Container, Paper, Grid, FormControl, makeStyles, Button, AppBar, Toolbar, Fade, TextField } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { Controller, ErrorMessage, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { showloader, hideloader} from 'store/actions/loaderActions'
import { show_alert} from 'store/actions/alertActions'
import { streamSKN} from 'model/participantmodel'
import { BlobToJSON } from 'helper/utils'

const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },

    identifyBox: {
        padding: 16
    },

    buttontoken: {
        height: 55,
    },

    smalltext: {
        fontSize: 12,
        padding: 0
    },

    certtitle: {
        fontSize: 30,
        textAlign:"center",
        fontWeight: "normal",
        lineHeight: 0.8
    },
    certtitlesmall: {
        fontSize: 20,
        textAlign:"center",
        fontWeight: "normal"
    },

    certtext: {
        fontSize: 30,
        textDecoration: "underline",
        textAlign:"center",
        fontWeight: "bold",
        lineHeight: 0.8
    },
    certtextsmall: {
        fontSize: 12,
        textDecoration: "none",
        textAlign:"center",
        fontWeight: "bold",
    }
  }));

const Certificate = props => {

    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const {register, control, errors, handleSubmit} = useForm()

    const handleCheckCertificate = data => {
        dispatch(showloader("Check Authentication.."))        
        streamSKN(data.certid).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: "Token Valid"
            }))
            const file = new Blob([res], {type: 'application/pdf'});

            const fileURL = URL.createObjectURL(file);
            
            window.open(fileURL);

        }).catch(err => {

            BlobToJSON(err, function(result) {
                dispatch(show_alert({
                    status: 'error',
                    message: JSON.parse(result).message
                }))
            })
            
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <Fade in={true} timeout={500}>
        <div className="App-header">
            <AppBar position="fixed" color="inherit" elevation={0}>
                <Toolbar>
                    <Button style={{color: "black"}} className={classes.backMenu} startIcon={<ArrowBackIcon/>} onClick={() => history.replace('/')}> HOME</Button>
                </Toolbar>
            </AppBar>
             <Container maxWidth="sm" style={{marginTop:-80}}>
                <Alert severity="info">
                    <AlertTitle>Attention</AlertTitle>
                    Input your exam token to check its credentials.
                </Alert>
                <br/>
                <form onSubmit={handleSubmit(handleCheckCertificate)}>
                <Paper elevation={3} className={classes.identifyBox}>
                    <Grid container spacing={1}>
                        <Grid item xs={10} sm={10}>
                            <FormControl fullWidth className={classes.root.margin} variant="outlined">
                                <Controller as={
                                    <TextField
                                        ref={register}
                                        helperText={(errors.certid) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="certid"/>}
                                        autoFocus={true}
                                        label="Exam token"
                                        variant="outlined"
                                        placeholder="Input your exam token number"
                                    />
                                } name="certid" control={control} defaultValue="" rules={{required: 'Exam token number is required'}} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Button variant="contained" color="primary" className={classes.buttontoken} fullWidth={true} type="submit">
                                <SearchIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                </form>
            </Container>
        </div>
    </Fade>
}

export default Certificate