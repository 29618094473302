import axios from 'axios'
import {api} from 'helper/utils'

export const getEmailTemplate = (token) => {
    return new Promise(function(resolve, reject){
        axios.get(api(`email`), {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const testSendTemplateEmail = (token, {email_type, email}) => {
    return new Promise(function(resolve, reject){
        axios.post(api(`email/test-send-template`), {
            email_type, email 
        }, {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateSendTemplateEmail = (token, id, data) => {
    return new Promise(function(resolve, reject){
        axios.patch(api(`email/${id}`), data, {
            headers: {
                'auth-token': token
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}