import axios from 'axios'
import {api} from 'helper/utils'
import moment from 'moment'

export const getExamSchedule = ({startDate, endDate}, reschedule = false) => {
    return new Promise(function(resolve, reject){
        axios.get(api(`exam?startdate=${startDate}&enddate=${endDate}&reschedule=${reschedule}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getScheduleList = sort => {
    return new Promise(function(resolve, reject){
        axios.get(api(`exam?sort=${sort}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data.data)
            } else {
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const addExamSchedule = (data, token) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("exam"), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteExamSchedule = (id, token) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`exam/${id}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const editExamSchedule = (id, data, token) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`exam/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const registerExam = (data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("participant/register"), data).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const rescheduleExam = (data) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api("exam/reschedule"), data).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const saveScore = (id, data) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`participant/${id}/saveresult`), data).then(res => {
            if(res.status === 200){
                resolve(res.data)
            }else{                
                reject(res.data.message)
            }
        }).catch(err => {
            reject((err.response) ? err.response.data : err)
        })
    })
}

export const sendResultEmail = (data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("exam/sendresult"), data).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const checkExamQuota = (examid) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`exam/checkquota/${examid}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getExamList = (examtype, examdate) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`exam/list?examtype=${examtype}&examdate=${examdate}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const generateCertificate = (data, token) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`exam/stream-certificate`), {ids: data}, {
            headers: {'auth-token': token},
            responseType: 'blob'
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateExamLimit = (token) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`exam/limit-update`), {token: token}).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadPaymentReceipt = (userid, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`participant/upload-payment-receipt/${userid}`), data, {
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getParticipantById = (examid) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`exam/participant/${examid}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getExamArchiveXLS = (token, {startDate, endDate}) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`exam/archive?startdate=${startDate}&enddate=${endDate}`), {
            headers: {'auth-token': token},
            responseType: 'blob'
        }).then(res => {
            if(res.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Archive-${moment(startDate).format('MM-YYYY')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                resolve({message: "Archive downloaded"})
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}