import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import VenueTable from './components/venuetable';
import ExamTypeTable from './components/examtypetable';
import EmailSetting from './components/emailsetting';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
        marginTop: theme.spacing(3),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(17),
      height: theme.spacing(17),
    },
    container: {
        padding: theme.spacing(2),
    },
    input: {
        marginBottom: 10
    }
}));

const Settings = props => {

    const classes = useStyles()
    const tabIndex = useSelector(state => state.subMenuState)

    const tabs = {
        EXAM: 0,
        VENUE: 1,
        EMAIL: 2
    }

    return <div className={classes.root}>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item lg={12} sm={12} xs={12}>
                    {(tabIndex === tabs.EXAM) && <ExamTypeTable />}

                    {(tabIndex === tabs.VENUE) && <VenueTable />}

                    {(tabIndex === tabs.EMAIL) && <EmailSetting />}
                </Grid>
            </Grid>
        </Container>
    </div>
}

export default Settings