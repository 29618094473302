import React, {useState, useEffect, useRef} from 'react'
import { AppBar, Toolbar, Button, makeStyles, Container, Grid, Paper, FormControl, TextField, InputAdornment, Divider, Fade, RadioGroup, Radio, FormLabel, FormControlLabel, InputLabel, Select, MenuItem } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import PollIcon from '@material-ui/icons/Poll';

import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import {getExamSchedule, registerExam, rescheduleExam, checkExamQuota} from 'model/exammodel'
import moment from 'moment'
import {show_alert} from 'store/actions/alertActions'
import {showloader, hideloader} from 'store/actions/loaderActions'
import {useDispatch} from 'react-redux'
import { getAllExamType } from 'model/settingmodel'

import 'main.scss'
import { show_dialog, hide_dialog } from 'store/actions/dialogActions'
import { faculty, majors } from 'helper/uinmajors'
import { testAlias } from 'helper/utils'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow:1,
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },

    identifyBox: {
        padding: 16
    },

    buttontoken: {
        height: 55,
    },

    smalltext: {
        fontSize: 12,
        padding: 0
    },

    calendarcontainer: {
        padding:16
    },

    formregister: {
        padding: 16
    },

    textInput: {
        marginBottom:10
    }
  }));

const Rescheduletest = props => {
    const calendarComponentRef = useRef()
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const {register, handleSubmit, control, errors } = useForm()

    const [disableForm, setDisableForm] = useState(true)
    const [examTypeList, setExamTypeList] = useState([])
    const [testType, setTestType] = useState("")
    const [selectedExam, setSelectedExam] = useState({
        data: {},
        extend: {},
        quota:0
    })
    const [exams, setExams] = useState([])
    const [genderValue, setGenderValue] = useState("female")
    const [statusValue, setStatusValue] = useState("student")
    const [programValue, setProgramValue] = useState("s1")
    const formState = "reschedule"
    const [selectedFaculty, setSelectedFaculty] = useState(0)
    const [uinFaculty, setUinFaculty] = useState("")
    const [uinMajors, setUinMajors] = useState("")
    const [birthdate, setbirthdate] = useState(null)

    const [calendarStartDate, setCalendarStartDate] = useState(
        moment().startOf('month').format('YYYY-MM-DD')
    )

    const [calendarEndDate, setCalendarEndDate] = useState(
        moment().endOf('month').format('YYYY-MM-DD')
    )

    const [calendarCurrentMonth, setCalendarCurrentMonth] = useState(
        calendarStartDate
    )

    function getSchedule({startDate, endDate}) {
        dispatch(showloader("Get data..."))
        getExamSchedule({
            startDate,
            endDate
        }, true).then(res => {
            if(res.data.length !== 0) {
                let exam = []
                res.data.forEach(ex => {
                    let tagColor = (ex.examtype.name === "TOAFL") ? "#009688" : (ex.examtype.name === "TOEFL") ? "#2196f3" : "#263238";
                    let tquota = 0
                    ex.venue.forEach(v => {
                        tquota += v.quota
                    })
                    exam.push({id: ex._id, examtypeid: ex.examtype._id, quota: tquota, title: `${testAlias(ex.examtype.name)} | ${moment(ex.starttime).format("HH:mm")}`, date: moment(ex.starttime).format("YYYY-MM-DD"), examstart: moment(ex.starttime), examend: moment(ex.endtime), backgroundColor: tagColor, textColor: '#fff', testtype: ex.examtype.name})
                })
                setExams(exam)
            }
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const setCalendarDate = ({curMonth, startDate, endDate}) => {
        setCalendarCurrentMonth(curMonth)
        setCalendarStartDate(
            startDate
        )
        setCalendarEndDate(
            endDate
        )
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate(curMonth)
        getSchedule({
            startDate,
            endDate
        })
    }

    const handleRegister = (data, e) => {
        data.exam = selectedExam.data.id
        data.faculty = uinFaculty
        data.majors = uinMajors
        data.program = programValue
        data.gender = genderValue
        data.occupation = statusValue
        data.birthdate = birthdate
        data.registrationfee = (statusValue === "general") ? 150000 : 75000
        dispatch(showloader("Processing registration..."))
        registerExam(data).then(res => {
            dispatch(show_dialog({
                title: "Registration Success",
                message: "Your registration success, please proceed to admin to finish your registration payment and get your exam token."
            }, () => {
                dispatch(hide_dialog())
            }))

            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            e.target.reset()
            setDisableForm(true)
            setSelectedExam({
                data: {},
                extend: {},
                quota: 0
            })
            dispatch(hideloader())
        })
    }

    const handleReschedule = (data, e) => {
        data.exam = selectedExam.data.id
        dispatch(showloader("Processing Reschedule..."))
        rescheduleExam(data).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            e.target.reset()
            setDisableForm(true)
            setSelectedExam({
                data: {},
                extend: {}
            })
        })
    }

    const getExamType = () => {
        getAllExamType().then(data => {            
            setExamTypeList(data)
            setTestType(data[0]._id)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        })
    }
    
    const onFacultyChange = (e) => {
        setSelectedFaculty(faculty.indexOf(e.target.value))
        setUinFaculty(e.target.value)
        setUinMajors(majors[faculty.indexOf(e.target.value)][0])
    }

    const onMajorsChange = (e) => {
        setUinMajors(e.target.value)
    }

    const handleBirthdateChange = e => {
        let dt = moment(e).format("YYYY-MM-DD")
        setbirthdate(dt);
    }

    const handleToday = () => {
        let curMonth = moment().format('YYYY-MM-DD')
        let startDate = moment(curMonth).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(curMonth).endOf('month').format('YYYY-MM-DD')

        setCalendarDate({curMonth, startDate, endDate})
    }

    const handlePrev = () => {
        let curMonth = moment(calendarCurrentMonth).subtract(1, 'month').format('YYYY-MM-DD')
        let startDate = moment(curMonth).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(curMonth).endOf('month').format('YYYY-MM-DD')

        setCalendarDate({curMonth, startDate, endDate})
    }

    const handleNext = () => {
        let curMonth = moment(calendarCurrentMonth).add(1, 'month').format('YYYY-MM-DD')
        let startDate = moment(curMonth).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(curMonth).endOf('month').format('YYYY-MM-DD')

        setCalendarDate({curMonth, startDate, endDate})
    }

    useEffect(() => {
        getSchedule({
            startDate: calendarStartDate,
            endDate: calendarEndDate
        })
        getExamType()
        // eslint-disable-next-line
    }, [])

    return <Fade in={true} timeout={500}>
        <div className="App-header2">
            <AppBar position="fixed" color="inherit" elevation={0}>
                <Toolbar>
                    <Button color="black" className={classes.backMenu} startIcon={<ArrowBackIcon/>} onClick={() => history.replace('/')}> HOME</Button>
                    <div className={classes.root}></div>
                    <Button color="black" startIcon={<EditIcon/>} onClick={() => history.replace('/register')}> REGISTER </Button>
                    <Button color="black" startIcon={<EventIcon/>} onClick={() => history.replace('/reschedule')}> RESCHEDULE </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg" style={{marginTop:80,marginBottom:80}}>
                <Grid container spacing={1}>
                    <Grid item lg={8} sm={12} xs={12}>
                        <Paper className={classes.calendarcontainer}>
                            <FullCalendar 
                                ref={calendarComponentRef}
                                defaultView="dayGridMonth" 
                                customButtons={
                                    {
                                        ctoday: {
                                            text: 'Today',
                                            click: () => handleToday()
                                        },
                                        cnext: {
                                            text: ">",
                                            click: () => handleNext()
                                        },
                                        cprev: {
                                            text: "<",
                                            click: () => handlePrev()
                                        }
                                    }
                                }
                                header={{
                                    left: 'title',
                                    right: 'ctoday cprev,cnext archive'
                                }}
                                plugins={[ dayGridPlugin ]} 
                                contentHeight={450}
                                events={exams}
                                eventClick={e => {                                             
                                    dispatch(showloader("Checking quota..."))
                                    checkExamQuota(e.event.id).then(res => {
                                        
                                        if(res.data.quota !== 0) {
                                            setTestType(e.event.extendedProps.examtypeid)                
                                            setSelectedExam({
                                                data: e.event,
                                                extend: e.event.extendedProps,
                                                quota: res.data.quota
                                            })
                                            setDisableForm(false)
                                        }else{
                                            dispatch(show_dialog({
                                                title: "Attention",
                                                message: "This exam has reach maximum quota, please select another exam",
                                            }, () => dispatch(hide_dialog())))
                                        }

                                    }).catch(err => dispatch(show_alert({
                                        status: 'error',
                                        message: err.message
                                    }))).finally(() => dispatch(hideloader()))
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item lg={4} sm={12} xs={12} style={{height:550,overflowY:'scroll'}}>
                        <Paper>
                            <div className={classes.formregister}>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel id="selectexamtypelabel">EXAM</InputLabel>
                                    <Select
                                        defaultValue=""
                                        value={testType}
                                        disabled={true}
                                        labelId="selectexamtypelabel"
                                        id="selectexamtype"
                                        >
                                            {examTypeList.map((v, i) => (
                                                <MenuItem key={i} value={v._id}>{testAlias(v.name)}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        disabled={true}
                                        label="Test Date"
                                        type="text"
                                        className={classes.textInput}
                                        autoFocus={true}
                                        id="outlined-name"
                                        value={(selectedExam.data.start) ? moment(selectedExam.extend.examstart).format("YYYY-MM-DD HH:mm:ss") : ""}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EventIcon style={{color: "#e0e0e0"}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        disabled={true}
                                        label="Quota"
                                        type="text"
                                        className={classes.textInput}
                                        autoFocus={true}
                                        id="outlined-name"
                                        value={selectedExam.quota}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PollIcon style={{color: "#e0e0e0"}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <Divider/>
                            {
                                (formState === "register") ? 
                                <form onSubmit={handleSubmit(handleRegister)} key="registerform">
                                    <div className={classes.formregister}>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'name'},{required: 'Name is required'})}
                                                    helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                                                    disabled={disableForm}
                                                    label="Name"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your Name"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircleIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="name" control={control} defaultValue="" />      
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.nip) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="nip"/>}
                                                    disabled={disableForm}
                                                    label="NIM"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your NIM/NIK/NIP"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <RecentActorsIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="nid" control={control} defaultValue="" rules={{required: 'NIM is required'}} />
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Gender</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender" row value={genderValue} onChange={(e) => setGenderValue(e.target.value)}>
                                                <FormControlLabel
                                                value="female"
                                                control={<Radio color="primary" />}
                                                label="Female"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                value="male"
                                                control={<Radio color="primary" />}
                                                label="Male"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.birthplace) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthplace"/>}
                                                    disabled={disableForm}
                                                    label="Birthplace"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your birthplace"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EventIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="birthplace" control={control} defaultValue="" rules={{required: 'This field is required'}} />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                value={birthdate}
                                                onChange={handleBirthdateChange}
                                                format="yyyy-MM-dd"
                                                id="date-picker-inline"
                                                label="Birthdate"
                                                disabled={disableForm}
                                                placeholder="yyyy-mm-dd"
                                                ref={register}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                helperText={(errors.birthdate) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthdate"/>}
                                            />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'email'},{required: 'Email is required'})}
                                                    helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                                    disabled={disableForm}
                                                    label="E-mail"
                                                    type="email"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your E-mail"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EmailIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="email" control={control} defaultValue="" />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'phone'},{required: 'Phone is required'})}
                                                    helperText={(errors.phone) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="phone"/>}
                                                    disabled={disableForm}
                                                    label="Phone"
                                                    type="number"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your phone number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PhoneIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="phone" control={control} defaultValue="" />
                                        </FormControl>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.address) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="address"/>}
                                                    disabled={disableForm}
                                                    label="Address"
                                                    type="number"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your address"
                                                    multiline
                                                    rows="4"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EditIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="address" control={control} defaultValue="" rules={{required: 'Address is required'}} />
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Status</FormLabel>
                                            <RadioGroup aria-label="status" name="status" row value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                                <FormControlLabel
                                                value="student"
                                                control={<Radio color="primary" />}
                                                label="UIN Jakarta Student"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                value="employee"
                                                control={<Radio color="primary" />}
                                                label="UIN Jakarta Teacher/Employee"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                value="general"
                                                control={<Radio color="primary" />}
                                                label="General"
                                                disabled={disableForm}
                                                labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {
                                            (statusValue === "student") && <div>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Program</FormLabel>
                                                    <RadioGroup aria-label="program" name="program" row value={programValue} onChange={(e) => setProgramValue(e.target.value)}>
                                                        <FormControlLabel
                                                        value="s1"
                                                        control={<Radio color="primary" />}
                                                        label="S1"
                                                        disabled={disableForm}
                                                        labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                        value="s2"
                                                        control={<Radio color="primary" />}
                                                        label="S2"
                                                        disabled={disableForm}
                                                        labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                        value="s3"
                                                        control={<Radio color="primary" />}
                                                        label="S3"
                                                        disabled={disableForm}
                                                        labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="selectfacultylabel">Faculty</InputLabel>
                                                    <Select
                                                        defaultValue=""
                                                        value={uinFaculty}
                                                        disabled={disableForm}
                                                        labelId="selectfacultylabel"
                                                        id="selectfaculty"
                                                        onChange={(e) => onFacultyChange(e)}
                                                        >
                                                            {faculty.map((v, i) => (
                                                                <MenuItem key={i} value={v}>{v}</MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="selectmajorlabel">Majors</InputLabel>
                                                    <Select
                                                        defaultValue=""
                                                        value={uinMajors}
                                                        disabled={disableForm}
                                                        labelId="selectmajorlabel"
                                                        id="selectmajor"
                                                        onChange={(e) => onMajorsChange(e)}
                                                        >
                                                            {majors[selectedFaculty].map((v, i) => (
                                                                <MenuItem key={i} value={v}>{v}</MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        }
                                        <Button variant="contained" fullWidth={true} color="primary" style={{marginTop:28}} disabled={disableForm} type="submit">REGISTER</Button>
                                    </div>
                                </form> :
                                <form key="rescheduleform" onSubmit={handleSubmit(handleReschedule)}>
                                    <div className={classes.formregister}>
                                        <FormControl fullWidth variant="outlined">
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.token) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="token"/>}
                                                    disabled={disableForm}
                                                    label="Token"
                                                    type="text"
                                                    className={classes.textInput}
                                                    id="outlined-name"
                                                    placeholder="Input your Token"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <EditIcon style={{color: "#e0e0e0"}}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            } name="token" control={control} defaultValue="" rules={{required: "Token is required"}} />      
                                        </FormControl>
                                        <Button variant="contained" fullWidth={true} color="primary" style={{marginTop:28}} disabled={disableForm} type="submit">RESCHEDULE</Button>
                                    </div>
                                </form>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </Fade>
}

export default Rescheduletest