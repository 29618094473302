import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { show_alert } from 'store/actions/alertActions'
import table from 'component/table'
import userdata from 'helper/userdata'
import { editExamType, getAllExamType } from 'model/settingmodel'
import MaterialTable from 'material-table';
import { testAlias } from 'helper/utils'
import moment from 'moment'

const ExamTypeTable = props => {

    const dispatch = useDispatch()
    const { token } = userdata
    const [isExamTableLoad, setIsExamTableLoad] = useState(false)
    const [columnExam, setColumnExam] = useState({
        columns: [
          { title: 'Name', field: 'name', editable: 'never' },
          { title: 'Steps', field: 'steps', editable: 'never' },
          { title: 'Time (m)', field: 'times' },
        ],
        data: []
    });

    const handleUpdateData = (newData, oldData) => new Promise((resolve, reject) => {
        let ndata = newData.times.split(',')
        if (ndata.length !== 3) {
            return reject()
        }

        editExamType(token, oldData._id, newData).then(data => {
            refreshExamTable()
            return resolve()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
            return reject()
        })
    })

    const refreshExamTable = () =>{
        setIsExamTableLoad(true)
        getAllExamType(token).then(data => {
            data.forEach((dt, i) => {
                let ndt = moment(dt.updated_at).fromNow()
                data[i].updated_at = ndt
                if(data[i].name === "TOEFL") {
                    data[i].name = testAlias(data[i].name)
                }
            })

            setColumnExam({
                columns: columnExam.columns,
                data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setIsExamTableLoad(false)
        })
    }

    useEffect(refreshExamTable, [])

    return <MaterialTable
        icons={table.icons}
        title="All Exam"
        columns={columnExam.columns}
        data={columnExam.data}
        isLoading={isExamTableLoad}
        options={{
            header: true,
            actionsColumnIndex:3
        }}
        editable={{
            onRowUpdate: (newData, oldData) => handleUpdateData(newData, oldData)
        }}
    />
}

export default ExamTypeTable