import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone'
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputAdornment, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Controller, ErrorMessage, useForm,  } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {showloader, hideloader} from 'store/actions/loaderActions'
import { show_alert } from 'store/actions/alertActions'
import {registerExam} from 'model/exammodel'
import { importParticipant, editParticipant } from 'model/participantmodel'
import { faculty, majors } from 'helper/uinmajors'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
    formControl: {
      marginBottom:10
    },
    dropzone: {
      marginTop:10,
      justifyContent:'center',
      alignContent: 'center',
      height:200,
      border: '1px solid #e0e0e0',
      borderRadius: 8,
      padding:32
    },
}));

const FormDialog = props => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const [error, setError] = useState(null)
    const [genderValue, setGenderValue] = useState(props.editData.gender)
    const [statusValue, setStatusValue] = useState("student")
    const [programValue, setProgramValue] = useState(props.editData.programValue)
    const [selectedFaculty, setSelectedFaculty] = useState((props.editData.uinFaculty !== "") ? faculty.indexOf(props.editData.uinFaculty) : 0)
    var [uinFaculty, setUinFaculty] = useState(props.editData.uinFaculty)
    var [uinMajors, setUinMajors] = useState(props.editData.uinMajors)
    var [birthdate, setbirthdate] = useState(props.editData.birthdate)
    const {register, control, errors, handleSubmit} = useForm()
    const participantID = props.editData.id

    const handleImportQuestion = (file) => {
        props.handleClose()
        let token = localStorage.getItem('token')
        let formData = new FormData()
        formData.append('file', file[0])
        formData.append('examid', props.examid)

        dispatch(showloader("Processing participant data..."))
        importParticipant(token, formData).then(res => {
            props.loadData()
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            setError(err.message)
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleAddParticipant(data) {
        let token = localStorage.getItem('token')
        data.exam = props.examid
        data.faculty = uinFaculty
        data.majors = uinMajors
        data.program = programValue
        data.gender = genderValue
        data.occupation = statusValue
        data.birthdate = birthdate
        data.registrationfee = (statusValue === "general") ? 150000 : 75000
        props.handleClose()
        dispatch(showloader("Saving data..."))
        if(participantID !== 0) {
            editParticipant(token, participantID, data).then(res => {
                props.loadData()
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }else{
            registerExam(data).then(res => {
                props.loadData()
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }
    }

    const onFacultyChange = (e) => {
        setSelectedFaculty(faculty.indexOf(e.target.value))
        setUinFaculty(e.target.value)
        setUinMajors(majors[faculty.indexOf(e.target.value)][0])
    }

    const onMajorsChange = (e) => {
        setUinMajors(e.target.value)
    }

    const handleBirthdateChange = e => {
        let dt = moment(e).format("YYYY-MM-DD")
        setbirthdate(dt);
    }

    useEffect(() => {
        setUinFaculty(props.editData.uinFaculty)
        setUinMajors(props.editData.uinMajors)
        setbirthdate(props.editData.birthdate)
        setGenderValue(props.editData.gender)
        setSelectedFaculty((props.editData.uinFaculty !== "") ? faculty.indexOf(props.editData.uinFaculty) : 0)
    }, [props.editData])

  return (
    <div>
        {(props.isImport) ? <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Import Participant</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Import participant, only support .xlsx format
            </DialogContentText>
            <Dropzone multiple={false} onDrop={acceptedFiles => handleImportQuestion(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop participant file, or click to select files</p>
                        {(error != null) && <p style={{color: 'red'}}>Error message here</p>}
                    </div>
                    </section>
                )}
            </Dropzone>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.handleClose}>
                Cancel
            </Button>
            <Button onClick={props.handleClose} color="primary">
                IMPORT
            </Button>
            </DialogActions></Dialog> :         
            
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(handleAddParticipant)}>
                <DialogTitle id="form-dialog-title">{(participantID !== 0) ? "Edit" : "Add"} Participant</DialogTitle>
                    <DialogContent>
                            <FormControl fullWidth className={classes.formControl}>
                                <Controller as={
                                    <TextField 
                                        ref={register}
                                        helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                                        label="Name"
                                        placeholder="Input your name"
                                        autoFocus={true}
                                        />
                                } name="name" control={control} defaultValue={props.editData.name} rules={{required: "Name is required"}} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Controller as={
                                    <TextField 
                                        ref={register}
                                        helperText={(errors.nid) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="nid"/>}
                                        label="NIK"
                                        placeholder="Input your NIK"
                                        autoFocus={true}
                                        />
                                } name="nid" control={control} defaultValue={props.editData.nid} rules={{required: "NIK is required"}} />
                            </FormControl>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Gender</FormLabel>
                                <RadioGroup aria-label="gender" name="gender" row value={genderValue} onChange={(e) => setGenderValue(e.target.value)}>
                                    <FormControlLabel
                                    value="female"
                                    control={<Radio color="primary" />}
                                    label="Female"
                                    labelPlacement="end"
                                    />
                                    <FormControlLabel
                                    value="male"
                                    control={<Radio color="primary" />}
                                    label="Male"
                                    labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <Controller as={
                                    <TextField
                                        ref={register}
                                        helperText={(errors.ttl) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthplace"/>}
                                        label="Birthplace"
                                        type="text"
                                        className={classes.textInput}
                                        id="outlined-name"
                                        placeholder="Input your birthplace"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EventIcon style={{color: "#e0e0e0"}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                } name="birthplace" control={control} defaultValue={props.editData.birthplace} rules={{required: 'This field is required'}} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        value={birthdate}
                                        onChange={handleBirthdateChange}
                                        format="yyyy-MM-dd"
                                        id="date-picker-inline"
                                        label="Birthdate"
                                        placeholder="yyyy-mm-dd"
                                        ref={register}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        helperText={(errors.birthdate) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="birthdate"/>}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Controller as={
                                    <TextField 
                                        ref={register}
                                        helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                        label="Email"
                                        type="email"
                                        placeholder="Input your email"
                                        />
                                } name="email" control={control} defaultValue={props.editData.email} rules={{required: "Email is required"}} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Controller as={
                                    <TextField 
                                        ref={register}
                                        helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="phone"/>}
                                        label="Phone Number"
                                        type="number"
                                        placeholder="Input your phone number"
                                        />
                                } name="phone" control={control} defaultValue={props.editData.phone} rules={{required: "phone number is required"}} />
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <Controller as={
                                    <TextField
                                        ref={register}
                                        helperText={(errors.address) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="address"/>}
                                        label="Address"
                                        type="number"
                                        className={classes.textInput}
                                        id="outlined-name"
                                        placeholder="Input your address"
                                        multiline
                                        rows="4"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EditIcon style={{color: "#e0e0e0"}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                } name="address" control={control} defaultValue={props.editData.address} rules={{required: 'Address is required'}} />
                            </FormControl>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Status</FormLabel>
                                <RadioGroup aria-label="status" name="status" row value={statusValue} onChange={(e) => setStatusValue(e.target.value)}>
                                    <FormControlLabel
                                    value="student"
                                    control={<Radio color="primary" />}
                                    label="UIN Jakarta Student"
                                    labelPlacement="end"
                                    />
                                    <br/>
                                    <FormControlLabel
                                    value="employee"
                                    control={<Radio color="primary" />}
                                    label="UIN Jakarta Teacher/Employee"
                                    labelPlacement="end"
                                    />
                                    <br/>
                                    <FormControlLabel
                                    value="general"
                                    control={<Radio color="primary" />}
                                    label="General"
                                    labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                            {
                                (statusValue === "student") && <div>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Program</FormLabel>
                                        <RadioGroup aria-label="program" name="program" row value={programValue} onChange={(e) => setProgramValue(e.target.value)}>
                                            <FormControlLabel
                                            value="s1"
                                            control={<Radio color="primary" />}
                                            label="S1"
                                            labelPlacement="end"
                                            checked={(props.editData.programValue === "s1") ? true : false}
                                            />
                                            <FormControlLabel
                                            value="s2"
                                            control={<Radio color="primary" />}
                                            label="S2"
                                            labelPlacement="end"
                                            checked={(props.editData.programValue === "s2") ? true : false}
                                            />
                                            <FormControlLabel
                                            value="s3"
                                            control={<Radio color="primary" />}
                                            label="S3"
                                            labelPlacement="end"
                                            checked={(props.editData.programValue === "s3") ? true : false}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="selectfacultylabel">Faculty</InputLabel>
                                        <Select
                                            defaultChecked={true}
                                            defaultValue={uinFaculty}
                                            value={uinFaculty}
                                            labelId="selectfacultylabel"
                                            id="selectfaculty"
                                            onChange={(e) => onFacultyChange(e)}
                                            >
                                                {faculty.map((v, i) => (
                                                    <MenuItem key={i} value={v}>{v}</MenuItem>
                                                ))}
                                        </Select>
                                        
                                    </FormControl>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel id="selectmajorlabel">Majors</InputLabel>
                                            <Select
                                                defaultChecked={true}
                                                defaultValue={uinMajors}
                                                value={uinMajors}
                                                labelId="selectmajorlabel"
                                                id="selectmajor"
                                                onChange={(e) => onMajorsChange(e)}
                                                >
                                                    {majors[selectedFaculty].map((v, i) => (
                                                        <MenuItem key={i} value={v}>{v}</MenuItem>
                                                    ))}
                                            </Select>
                                    </FormControl>
                                    
                                </div>
                            }
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit">
                        SAVE
                    </Button>
                    </DialogActions>
                </form>
            </Dialog>}
    </div>
  )
}

export default FormDialog