import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { testAlias } from 'helper/utils';
import moment from 'moment'
import { hideloader, showloader } from 'store/actions/loaderActions';
import lang from 'helper/localize';
import { show_alert } from 'store/actions/alertActions';
import { rescheduleParticipant } from 'model/participantmodel';

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
    },
    formControl: {
      marginBottom:10
    }
}));

const FormDialog = props => {
    const token = localStorage.getItem('token')
    const classes = useStyles()
    const dispatch = useDispatch()

    const [examList, setExamList] = useState([])
    const [examIdValue, setExamIdValue] = useState(props.examId)
    const {handleSubmit} = useForm()
    const participant = props.participant

    const onExamListChange = (e) => {
        setExamIdValue(e.target.value)
    }

    useEffect(() => {
        setExamIdValue(props.examId)
        setExamList(props.examList)
    }, [props.examId, props.examList])

    function examTitle(exam) {
        return `${testAlias(exam.examtype.name)} | ${moment(exam.starttime).format("dddd, Do MMM YYYY")} ${moment(exam.starttime).format("HH:mm")} - ${moment(exam.endtime).format("HH:mm")}`
    }

    async function handleRescheduleParticipant() {
        try {
            props.handleClose()
            dispatch(showloader(lang.loading))
            let response = await rescheduleParticipant({
                id: participant._id,
                examid: examIdValue
            }, token)
            dispatch(show_alert({
                status: 'success',
                message: response.message ?? response
            }))
        } catch (error) {
            dispatch(show_alert({
                status: 'error',
                message: error.message
            }))
        }
        dispatch(hideloader())
        props.refreshTable()
    }

  return (
    <div>        
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(handleRescheduleParticipant)}>
            <DialogTitle id="form-dialog-title">Reschedule Participant</DialogTitle>
                <DialogContent>
                    {participant.name} | {participant.nid}
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="selectExamSchedule">Exam Schedule</InputLabel>
                        <Select
                            defaultChecked={true}
                            defaultValue={examIdValue}
                            value={examIdValue}
                            labelId="selectExamSchedule"
                            id="selecExamSchedule"
                            onChange={(e) => onExamListChange(e)}
                            >
                                {examList?.map((v, i) => (
                                    <MenuItem key={i} value={v._id}>{examTitle(v)}</MenuItem>
                                ))}
                        </Select>
                        
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button color="primary" type="submit">
                    SAVE
                </Button>
                </DialogActions>
            </form>
        </Dialog>
    </div>
  )
}

export default FormDialog