import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { RadioGroup, FormControlLabel, Radio, Button, Grid } from '@material-ui/core';
import renderHTML from 'react-render-html'
import lang from 'helper/localize'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: "#003366",
    color: 'white'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  instruction: {
    '& h1': {
      textAlign: 'center'
    },
    '& h2': {
      textAlign: 'center'
    },
    '& h3': {
      textAlign: 'center'
    },
    '& p': {
      lineHeight: '1.5'
    }
  },
  arabicFont: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "right",
    fontFamily: "Scheherazade, sans-serif"
  },
  normalFont: {
      fontSize: 16,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const classes = useStyles();
    const letterOptions = ['A','B','C','D']

    return (
        <div>
        <Dialog fullScreen open={props.isopen} onClose={props.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                Instruction Page
                </Typography>
                {(props.closeable === true) ? <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                <CloseIcon />
                </IconButton> : null}
            </Toolbar>
            </AppBar>
            <Grid container dir={(props.rtl === true) ? "rtl" : "ltr"}>
              <div style={{padding:64,textAlign:(props.rtl === true) ? "right" : "left"}} className={classes.instruction}>
                  <span className={(props.rtl === true) ? classes.arabicFont : classes.normalFont }>{(props.instruction) && renderHTML(props.instruction)}</span>
                  {(props.question && (props.question !== null || props.question !== "")) && <div>
                    <h1>Example</h1>
                    <p>{(props.question) && props.question}</p>
                    {(props.options) && <RadioGroup aria-label="answer" name="answer" value={props.answer} align="left">
                        {props.options.map((q, i) => <FormControlLabel 
                            disabled
                            key={i}
                            value={q}
                            control={<div><Radio key={i} color="primary" checked={(q === props.answer) ? true : false} /></div>}
                            label={<span>({letterOptions[i]})&nbsp;&nbsp;&nbsp;{q}</span>}
                            labelPlacement="end"    
                        />)}
                    </RadioGroup>}
                  </div>}
                  {(props.closeable) && <Button variant="contained" color="primary" onClick={props.handleClose}>
                    {lang.next}
                  </Button>}
              </div>
            </Grid>
        </Dialog>
        </div>
    );
}
