import React, {useState, useEffect} from 'react'
import { addVenue, deleteVenue, editVenue, getAllVenue } from 'model/settingmodel'
import userData from 'helper/userdata'
import table from 'component/table'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { show_alert } from 'store/actions/alertActions'
import MaterialTable from 'material-table';

const VenueTable = props => {

    const dispatch = useDispatch()
    const {token} = userData
    const [isVenueTableLoad, setIsVenueTableLoad] = useState(false)
    const [columnVenue, setColumnVenue] = useState({
        columns: [
          { title: 'Name', field: 'name' },
          { title: 'Code', field: 'code' },
          { title: 'Quota', field: 'quota' },
          { title: 'Time', field: 'updated_at', editable: 'never' },
        ],
        data: []
    });

    const handleAddData = newData => new Promise((resolve, reject) => {
        addVenue(token, newData).then(() => {
            refreshVenueTable()
            resolve()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
            reject()
        })
    })

    const handleEditData = (newData, oldData) => new Promise((resolve, reject) => {
        editVenue(token, oldData._id, newData).then(() => {
            refreshVenueTable()
            resolve()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
            reject()
        })
    })

    const handleDeleteData = oldData => new Promise((resolve, reject) => {
        deleteVenue(token, oldData._id).then(data => {
            refreshVenueTable()
            resolve()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
            reject()
        })
    })

    const refreshVenueTable = () => {
        setIsVenueTableLoad(true)
        getAllVenue(token).then(data => {            
            data.forEach((dt, i) => {
                let ndt = moment(dt.updated_at).fromNow()
                data[i].updated_at = ndt
            })

            setColumnVenue({
                columns: columnVenue.columns,
                data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setIsVenueTableLoad(false)
        })
    }

    useEffect(refreshVenueTable, [])

    return <MaterialTable
        icons={table.icons}
        title="All Venue"
        columns={columnVenue.columns}
        data={columnVenue.data}
        isLoading={isVenueTableLoad}
        options={{
            header: true,
            actionsColumnIndex:4
        }}
        editable={{
            onRowAdd: newData => handleAddData(newData),
            onRowUpdate: (newData, oldData) => handleEditData(newData, oldData),
            onRowDelete: oldData => handleDeleteData(oldData)
        }}
    />
}

export default VenueTable