import {
    NEXT_QUESTIONS,
    BACK_QUESTIONS,
    JUMP_QUESTIONS,
    STEPS_QUESTIONS,
    TIMEUP,
    PROCESS_NEXT,
    PROCESS_BACK,
    PROCESS_JUMP,
    HELP,
    ANSWER_TIME_UP,
    ANSWER_TIME_RESET,
    AUDIO_VOLUME,
    SHOW_INSTRUCTION,
    CLOSE_INSTRUCTION,
    SET_LISTENING_PART,
    LISTENING_PART_B,
    LISTENING_PART_C,
    SET_EXAM_TYPE,
    NEXT_LISTENING_PART_QUESTION_STATE,
    JUMP_LISTENING_PART_QUESTION_STATE,
    EXAM_STEP_CATEGORY,
    SET_STEP
} from 'store/actions/examActions'

export const QuestionsReducers = (state = 0, action) => {
    switch(action.type) {
        case NEXT_QUESTIONS:
            return state += 1
        case BACK_QUESTIONS:
            return state -= 1
        case JUMP_QUESTIONS:
            return state = action.index
        default:
            return state
    }
}

export const StepsReducers = (state = 0, action) => {
    switch(action.type) {
        case STEPS_QUESTIONS:
            return state += 1
        case SET_STEP:
            return state = action.step
        default:
            return state
    }
}

export const TimeReducers = (state = "", action) => {
    switch(action.type) {
        case TIMEUP:
            return state = action.newtime
        default:
            return state
    }
}

export const ProcessingReducers = (state = 0, action) => {
    switch(action.type) {
        case PROCESS_NEXT:
            return state += 1
        case PROCESS_BACK:
            return state -= 1
        case PROCESS_JUMP:
            return state = action.index
        default:
            return state
    }
}

export const HelpReducers = (state = false, action) => {
    switch(action.type) {
        case HELP:
            return !state
        default:
            return state
    }
}

export const AnswerTimeReducers = (state = 0, action) => {
    switch(action.type) {
        case ANSWER_TIME_UP:
            return state -= 1
        case ANSWER_TIME_RESET:
            return action.value
        default:
            return state
    }
}

export const ListeningAudioReducers = (state = 5, action) => {
    switch(action.type) {
        case AUDIO_VOLUME:
            return state = action.value
        default:
            return state
    }
}

export const InstructionPageReducers = (state = {isopen: false, instruction: "", question: "", options: [], answer: "", audio: "", closeable: true, rtl: false}, action) => {
    switch(action.type) {
        case SHOW_INSTRUCTION:
            return state = {
                isopen: true,
                instruction: action.value.instruction,
                question: action.value.question,
                options: action.value.options,
                answer: action.value.answer,
                audio: action.value.audio,
                closeable: action.value.closeable,
                rtl: action.value.rtl
            }
        case CLOSE_INSTRUCTION:
            return state = {
                isopen: false
            }
        default:
            return state
    }
}

export const ListeningPartReducers = (state = "a", action) => {
    switch (action.type) {
        case SET_LISTENING_PART:
            return state = action.value
        default:
            return state
    }
}

export const ListeningPartBReducers = (state = 0, action) => {
    switch (action.type) {
        case LISTENING_PART_B:
            return state = (state < 0) ? 0 : (state < action.max-1) ? state+1 : state
        default:
            return state;
    }
}

export const ListeningPartCReducers = (state = 0, action) => {
    switch (action.type) {
        case LISTENING_PART_C:
            return state = (state < 0) ? 0 : (state < action.max-1) ? state+1 : state
        default:
            return state;
    }
}

export const ExamTypeReducers = (state = "TOEFL", action) => {
    switch (action.type) {
        case SET_EXAM_TYPE:
            return state = action.value
        default:
            return state;
    }
}

export const ListeningPartQuestionStateReducers = (state = 0, action) => {
    switch (action.type) {
        case NEXT_LISTENING_PART_QUESTION_STATE:
            return state += 1
        case JUMP_LISTENING_PART_QUESTION_STATE:
            return state = action.value
        default:
            return state;
    }
}

export const ExamStepCategoryReducers = (state = "", action) => {
    switch (action.type) {
        case EXAM_STEP_CATEGORY:
            return state = action.value
        default:
            return state;
    }
}